import { MutableRefObject, useContext, useMemo } from 'react';
import { AuthContext } from '../../../auth/context';
import { PlayingMode, ProgressMode, TimerMode } from '../../types/Timer';
import { WorkCycle } from '../../types/WorkCycle';
import { useOfflineTimerControls } from './useOfflineTimerControls';
import { useOnlineTimerControls } from './useOnlineTimerControls';

export interface TimerControlsResponse {
  playOrPauseTimer: () => void;
  startTimer: () => void;
  pauseTimer: () => void;
  finishTimer: () => void;
  restartTimer: () => void;
  startBreak: () => void;
  forwardTimer: () => void;
  currentSeconds: number;
  percentage: number;
  progressMode: ProgressMode;
  timerMode: TimerMode;
  playingMode: PlayingMode;
  humanTimerMode: string;
}

export interface TimerControlProps {
  currentWorkCycleState: [WorkCycle | null, React.Dispatch<React.SetStateAction<WorkCycle | null>>];
  currentSecondsState: [number, React.Dispatch<React.SetStateAction<number>>];
  totalSecondsState: [number, React.Dispatch<React.SetStateAction<number>>];
  progressModeState: [ProgressMode, React.Dispatch<React.SetStateAction<ProgressMode>>];
  timerModeState: [TimerMode, React.Dispatch<React.SetStateAction<TimerMode>>];
  playingModeState: [PlayingMode, React.Dispatch<React.SetStateAction<PlayingMode>>];
  lastPlayState: [TimerMode, React.Dispatch<React.SetStateAction<TimerMode>>];
  percentageState: [number, React.Dispatch<React.SetStateAction<number>>];
  interval: MutableRefObject<any>;
  timeout: MutableRefObject<any>;
}

type UseTimerControls = (props: TimerControlProps) => TimerControlsResponse;

export const useTimerControls: UseTimerControls = ({
  currentWorkCycleState,
  currentSecondsState,
  totalSecondsState,
  progressModeState,
  timerModeState,
  playingModeState,
  lastPlayState,
  percentageState,
  interval,
  timeout,
}) => {
  const { currentUser } = useContext(AuthContext);

  // TODO: Only run if is offline
  const offlineTimerControls = useOfflineTimerControls({
    currentSecondsState,
    progressModeState,
    timerModeState,
    lastPlayState,
    percentageState,
    interval,
    timeout,
  });

  // TODO: Only run if is online
  const onlineTimerControls = useOnlineTimerControls({
    currentWorkCycleState,
    currentSecondsState,
    totalSecondsState,
    progressModeState,
    timerModeState,
    playingModeState,
    percentageState,
    interval,
    timeout,
  });

  const result = useMemo(() => {
    if (currentUser) {
      return onlineTimerControls;
    } else {
      return offlineTimerControls;
    }
  }, [currentUser, onlineTimerControls, offlineTimerControls]);

  return result;
};
