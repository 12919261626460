import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Button, Icon, IconButton, VStack, useColorModeValue } from 'native-base';
import React, { useContext } from 'react';
import { useI18n } from '../../../../../hooks/useI18n';
import { HomeScreenNavProps } from '../../HomeNavigator';
import { SideMenuContext } from '../../context/SidemenuContext';

export const SidebarBody: React.FC = () => {
  const activeSectionColor = useColorModeValue('blueGray.100', 'blueGray.700');
  const { isSideMenuCollapsed, isLargeScreen } = useContext(SideMenuContext);

  const { navigate, getState } = useNavigation<HomeScreenNavProps['navigation']>();
  const state = getState();
  const i18n = useI18n();

  return (
    <VStack px="2" py="4">
      {isSideMenuCollapsed && isLargeScreen ? (
        <>
          <IconButton
            {...buttonProps}
            variant="ghost"
            justifyContent="flex-start"
            bg={
              state && state.routes[state.index]?.name === 'Welcome'
                ? activeSectionColor
                : 'transparent'
            }
            onPress={() => {
              navigate('Timer');
            }}
            icon={<Icon size="5" mr="2" as={Entypo} name="time-slot" />}
            accessibilityLabel={i18n.t('app.sections.timer')}
          />
          <IconButton
            {...buttonProps}
            variant="ghost"
            justifyContent="flex-start"
            bg={
              state && state.routes[state.index]?.name === 'Welcome'
                ? activeSectionColor
                : 'transparent'
            }
            onPress={() => {
              navigate('Welcome', {});
            }}
            icon={<Icon size="5" mr="2" as={MaterialIcons} name="home" />}
            accessibilityLabel="Welcome"
          />
        </>
      ) : (
        <>
          <Button
            {...buttonProps}
            variant="ghost"
            justifyContent="flex-start"
            bg={
              state && state.routes[state.index]?.name === 'Welcome'
                ? activeSectionColor
                : 'transparent'
            }
            onPress={() => {
              navigate('Timer');
            }}
            accessibilityLabel={i18n.t('app.sections.timer')}
            leftIcon={<Icon size="5" mr="2" as={Entypo} name="time-slot" />}>
            {i18n.t('app.sections.timer')}
          </Button>
          <Button
            {...buttonProps}
            variant="ghost"
            justifyContent="flex-start"
            bg={
              state && state.routes[state.index]?.name === 'Welcome'
                ? activeSectionColor
                : 'transparent'
            }
            onPress={() => {
              navigate('Welcome', {});
            }}
            leftIcon={<Icon size="5" mr="2" as={MaterialIcons} name="edit" />}>
            Welcome
          </Button>
        </>
      )}
    </VStack>
  );
};

const buttonProps = {
  py: '3',
  px: '5',
  _light: {
    _text: { color: 'coolGray.800' },
    _icon: { color: 'coolGray.800' },
  },
  _dark: {
    _text: { color: 'coolGray.50' },
    _icon: { color: 'coolGray.50' },
  },
  _text: {
    fontSize: 'md',
    fontWeight: 'medium',
  },
  _hover: {
    _text: {
      _light: {
        color: 'primary.900',
      },
      _dark: {
        color: 'primary.500',
      },
    },

    _icon: {
      _light: {
        color: 'primary.900',
      },
      _dark: {
        color: 'primary.500',
      },
    },
    _light: {
      bg: 'primary.100',
    },
    _dark: {
      bg: 'coolGray.800',
    },
  },
};
