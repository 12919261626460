import { StackScreenProps, createStackNavigator } from '@react-navigation/stack';
import isElectron from 'is-electron';
import React from 'react';
import { Platform } from 'react-native';
import { NotFoundScreen } from '../../notFound/NotFoundScreen';
import { TimerScreen } from '../../timer/TimerScreen';
import { TimerWidget } from '../../timer/components/TimerWidget/TimerWidget';
import {
  SignInByEmailScreen,
  SignInByPhoneNumberScreen,
  SignUpByEmailScreen,
  SignUpByPhoneNumberScreen,
} from '../screens';
import { ForgotPasswordScreen } from '../screens/ForgotPasswordScreen';

export type AuthEmailStackParams = {
  Timer: undefined;
  TimerWidget: undefined;
  Splash: undefined;
  SignUp: undefined;
  SignIn: undefined;
  ForgotPassword: undefined;
  'Not found': undefined;
};

export type AuthScreenNavProps = StackScreenProps<AuthEmailStackParams>;

const StackEmail = createStackNavigator<AuthEmailStackParams>();

export const AuthEmailNavigator: React.FC = () => {
  return (
    <StackEmail.Navigator>
      {!isElectron() && Platform.OS != 'android' && Platform.OS != 'ios' && (
        <StackEmail.Screen name="Timer" component={TimerScreen} options={{ headerShown: false }} />
      )}
      <StackEmail.Screen
        name="SignUp"
        component={SignUpByEmailScreen}
        options={{ headerShown: false }}
      />
      <StackEmail.Screen
        name="SignIn"
        component={SignInByEmailScreen}
        options={{ headerShown: false }}
      />
      <StackEmail.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{ headerShown: false }}
      />
      <StackEmail.Screen
        name="Not found"
        component={NotFoundScreen}
        options={{ headerShown: false }}
      />
      {isElectron() && (
        <StackEmail.Screen
          name="TimerWidget"
          component={TimerWidget}
          options={{ headerShown: false }}
        />
      )}
    </StackEmail.Navigator>
  );
};

export type AuthPhoneNumberStackParams = {
  SignUp: undefined;
  SignIn: {
    phoneNumber: string;
  };
  'Not found': undefined;
};

const StackPhoneNumber = createStackNavigator<AuthPhoneNumberStackParams>();

export const AuthPhoneNumberNavigator: React.FC = () => {
  return (
    <StackPhoneNumber.Navigator>
      <StackPhoneNumber.Screen
        name="SignUp"
        component={SignUpByPhoneNumberScreen}
        options={{ headerShown: false }}
      />
      <StackPhoneNumber.Screen
        name="SignIn"
        component={SignInByPhoneNumberScreen}
        options={{ headerShown: false }}
      />
      <StackPhoneNumber.Screen
        name="Not found"
        component={NotFoundScreen}
        options={{ headerShown: false }}
      />
    </StackPhoneNumber.Navigator>
  );
};
