import { useState } from 'react';

interface UseTimerConfigResult {
  pomodoroSeconds: number;
  breakSeconds: number;
  muted: boolean;
  autoPlay: boolean;
}

type UseTimerConfig = () => UseTimerConfigResult;

const useTimerConfig: UseTimerConfig = () => {
  const [pomodoroSeconds] = useState<UseTimerConfigResult['pomodoroSeconds']>(25 * 60);
  const [breakSeconds] = useState<UseTimerConfigResult['breakSeconds']>(5 * 60);
  const [muted] = useState<UseTimerConfigResult['muted']>(false);
  const [autoPlay] = useState<UseTimerConfigResult['autoPlay']>(false);

  return {
    pomodoroSeconds,
    breakSeconds,
    muted,
    autoPlay,
  };
};

export default useTimerConfig;
