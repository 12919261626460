import { Text } from 'native-base';
import React from 'react';
import { DashboardLayout } from '../../layouts';
import { HomeScreenProps } from '../home/navigation/HomeNavigator';

export type SettingsScreenProps = HomeScreenProps<'Settings'>;

export const SettingsScreen = (props: SettingsScreenProps) => {
  return (
    <DashboardLayout
      title="Settings"
      displayMenuButton
      displayScreenTitle={false}
      displayAlternateMobileHeader
      rightPanelMobileHeader>
      <Text>Settings !</Text>
    </DashboardLayout>
  );
};
