import camelCase from 'lodash.camelcase';

export type PlainObject = { [k: string]: unknown };
type UnknownArray = unknown[];

export const deepCamelCaseKeys = (obj: PlainObject | UnknownArray): PlainObject | UnknownArray => {
  if (Array.isArray(obj)) {
    return obj.map((value) => deepCamelCaseKeys(value as PlainObject | UnknownArray));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result: PlainObject, key: string) => {
      result[camelCase(key)] = deepCamelCaseKeys(
        (obj as PlainObject)[key] as PlainObject | UnknownArray
      );
      return result;
    }, {});
  }
  return obj;
};
