import { MouseEventHandler } from 'react';

let animationId: number;
let mouseX: number;
let mouseY: number;

let isMoving = false;

export const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
  mouseY = e.clientY;
  mouseX = e.clientX;
  isMoving = true;

  document.addEventListener('mouseup', onMouseUp);
  requestAnimationFrame(moveWindow);

  return true;
};

export const onDoubleClick = () => {
  window.api.send('openSettings');
};

const onMouseUp = () => {
  if (isMoving) {
    window.api.send('windowMoved');
    document.removeEventListener('mouseup', onMouseUp);
    cancelAnimationFrame(animationId);
    isMoving = false;
  }
};

const moveWindow = () => {
  if (isMoving) {
    window.api.send('windowMoving', { mouseX, mouseY });
    animationId = requestAnimationFrame(moveWindow);
  }
};
