import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Box, HStack, Icon, IconButton, Text } from 'native-base';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { AuthContext } from '../../../auth/context';
import { HomeScreenNavProps } from '../../../home';
import { HeaderRightMenu } from '../../../home/navigation/HeaderRightMenu';
import { Logo } from '../../../home/navigation/components/Logo';

interface TimerContainerProps {}

const TimerContainer: React.FC<PropsWithChildren<TimerContainerProps>> = ({ children }) => {
  const { isSignedIn } = useContext(AuthContext);
  const { navigate } = useNavigation<HomeScreenNavProps['navigation']>();

  return (
    <>
      <Box w="full" h="full">
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundSize: '100% 100%',
            backgroundPosition: '0% 0%',
            backgroundImage: 'radial-gradient(75% 75% at 50% 50%, #5B4483FF 0%, #261447 100%)',
          }}>
          <HStack
            px={10}
            py={3}
            alignItems="center"
            justifyContent="space-between"
            position="fixed"
            w="full">
            {isSignedIn ? (
              <IconButton
                icon={<Icon as={Entypo} name="home" size="2xl" color="white" />}
                onPress={() => navigate('Welcome', {})}
              />
            ) : (
              <Logo />
            )}
            <HeaderRightMenu hideSearchBar />
          </HStack>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {children}
          </div>
        </div>
      </Box>
    </>
  );
};

export default TimerContainer;
