import styled, { css } from 'styled-components';

export const CountdownWrapper = styled.div`
  width: 100px;
  height: 110px;
`;

interface CountdownProps {
  blink?: string;
}

export const Countdown = styled.div<CountdownProps>`
  background-color: #333;
  width: calc(100% - 0.4rem);
  height: calc(100% - 0.4rem - 10px);
  border-radius: 50%;
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
  padding: 5px;

  box-shadow: 0rem 0rem 0.3rem #333;

  /* @media (prefers-reduced-motion: no-preference) {
    animation: timer-float infinite 3s ease-in-out;
  } */

  @keyframes timer-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  ${(props: CountdownProps) =>
    props.blink &&
    css`
      animation: blink-animation 1.5s steps(2, start) infinite;
      -webkit-animation: blink-animation 1.5s steps(2, start) infinite;
      @keyframes blink-animation {
        to {
          opacity: 0.8;
        }
      }
      @-webkit-keyframes blink-animation {
        to {
          opacity: 0.8;
        }
      }
    `}
`;
