import React from 'react';
import { Timer } from './components/Timer/Timer';
import TimerContainer from './components/TimerContainer/TimerContainer';

export const TimerScreen = () => {
  return (
    <TimerContainer>
      <Timer />
    </TimerContainer>
  );
};
