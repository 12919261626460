import { useState } from 'react';
import bellAudio from './assets/bell2.wav';
import tickAudio from './assets/tick2.wav';

const tick = new Audio(tickAudio);
const bell = new Audio(bellAudio);

export const useTimerAudio = () => {
  const [bellAudio] = useState(bell);
  const [tickAudio] = useState(tick);

  const playBell = () => {
    bellAudio.play();
  };

  const pauseBell = () => {
    bellAudio.pause();
  };

  const isPlayingBell = () => {
    return bellAudio.currentTime > 0;
  };

  const resetBell = () => {
    bellAudio.currentTime = 0;
  };

  const stopBell = () => {
    pauseBell();
    resetBell();
  };

  const playTick = () => {
    tickAudio.play();
  };

  const pauseTick = () => {
    tickAudio.pause();
  };

  const isPlayingTick = () => {
    return tickAudio.currentTime > 0;
  };

  const resetTick = () => {
    tickAudio.currentTime = 0;
  };

  const stopTick = () => {
    pauseTick();
    resetTick();
  };

  const stopSounds = () => {
    stopBell();
    stopTick();
  };

  return {
    playBell,
    pauseBell,
    isPlayingBell,
    resetBell,
    stopBell,
    playTick,
    pauseTick,
    isPlayingTick,
    resetTick,
    stopTick,
    stopSounds,
  };
};
