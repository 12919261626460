import { getApolloClient } from '../../clients/apollo';
import {
  WorkCycleCurrentDocument,
  WorkCycleCurrentQuery,
  WorkCycleCurrentQueryVariables,
  WorkCycleUpdateEventDocument,
  WorkCycleUpdateEventMutation,
  WorkCycleUpdateEventMutationVariables,
} from '../../generated/graphql';

export const workCycleCurrentQuery = async () => {
  const result = await (
    await getApolloClient()
  ).query<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>({
    query: WorkCycleCurrentDocument,
    fetchPolicy: 'network-only',
    variables: {},
  });
  return result?.data?.work_cycle_current;
};

export const workCycleUpdateEvent = async (variables: WorkCycleUpdateEventMutationVariables) => {
  const result = await (
    await getApolloClient()
  ).mutate<WorkCycleUpdateEventMutation, WorkCycleUpdateEventMutationVariables>({
    mutation: WorkCycleUpdateEventDocument,
    fetchPolicy: 'network-only',
    variables,
  });

  return result?.data?.work_cycle_update_event;
};
