import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { Config } from '../../../config';
import { PlainObject, deepCamelCaseKeys } from '../../../helpers/deepCamelCaseKeys';

const pusher = new Pusher(Config.PUSHER_API_KEY, {
  cluster: Config.PUSHER_CLUSTER,
});

type UseRealTime = <T = any>(channelName: string | null, eventName: string) => T | null;

export const useRealtime: UseRealTime = <T = any>(
  channelName: string | null,
  eventName: string
) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (channelName) {
      let channel;
      (async () => {
        channel = pusher.subscribe(channelName);

        channel.bind(eventName, (data: T) => {
          const obj = deepCamelCaseKeys(data as PlainObject);
          setData(obj as T);
        });
      })();

      return () => {
        pusher.unbind(eventName);
        pusher.unsubscribe(channelName);
      };
    }
  }, [channelName]);

  return data;
};
