import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601DateTime: string;
  bigint: number;
  jsonb: any;
  numeric: number;
  timestamp: string;
  timestamptz: string;
};

export type AasmField = {
  aasm_field: Scalars['String'];
  human_aasm_field: Scalars['String'];
  id: Scalars['Int'];
  possible_events: Array<AasmPossibleEvents>;
};

/** Get available operations for a specific aasm state attribute in a model */
export type AasmFieldsAttributes = {
  /** Model attribute name */
  attribute: Scalars['String'];
  /** Model instance id */
  record_id: Scalars['bigint'];
  /** Model class name */
  record_type: Scalars['String'];
};

export type AasmPossibleEvents = {
  label: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Attachment = {
  blob: Blob;
  created_at: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  medium_url?: Maybe<Scalars['String']>;
  small_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** Attributes for query attachments for a specific model */
export type AttachmentsAttributes = {
  /** Model attribute name */
  attribute: Scalars['String'];
  /** Model instance id */
  record_id: Scalars['bigint'];
  /** Model class name */
  record_type: Scalars['String'];
};

export type Blob = {
  byte_size: Scalars['bigint'];
  checksum: Scalars['String'];
  content_type: Scalars['String'];
  created_at: Scalars['ISO8601DateTime'];
  filename: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Autogenerated input type of CreateAttachment */
export type CreateAttachmentInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  related_id: Scalars['Int'];
  related_type: Scalars['String'];
  signed_id: Scalars['String'];
};

export type CreateAttachmentOutput = {
  attachment: Attachment;
};

/** Autogenerated input type of CreateAttachments */
export type CreateAttachmentsInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  related_id: Scalars['Int'];
  related_type: Scalars['String'];
  signed_ids: Array<Scalars['String']>;
};

export type CreateAttachmentsOutput = {
  attachments: Array<Attachment>;
};

export type CreateDirectUploadInput = {
  byte_size: Scalars['Int'];
  checksum: Scalars['String'];
  content_type: Scalars['String'];
  filename: Scalars['String'];
};

export type CreateDirectUploadOutput = {
  direct_upload?: Maybe<Scalars['jsonb']>;
};

/** Autogenerated input type of DestroyAttachment */
export type DestroyAttachmentInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  related_id: Scalars['Int'];
  related_type: Scalars['String'];
};

/** Autogenerated return type of DestroyAttachment */
export type DestroyAttachmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserOutput = {
  avatar?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUserUsersPkColumnsInput = {
  id: Scalars['bigint'];
};

export type UpdateUserUsersSetInput = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  blob?: Maybe<UploadFileBlob>;
  created_at: Scalars['String'];
  id: Scalars['Int'];
  medium_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type UploadFileBlob = {
  byte_size: Scalars['Int'];
  checksum: Scalars['String'];
  content_type: Scalars['String'];
  created_at: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
};

export type User = {
  avatar?: Maybe<UploadFile>;
  created_at?: Maybe<Scalars['String']>;
  current_password_required?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type WorkCycle = {
  aasm_state: Scalars['String'];
  break_end_at?: Maybe<Scalars['timestamptz']>;
  break_end_scheduled_at?: Maybe<Scalars['timestamptz']>;
  break_interval?: Maybe<Scalars['Int']>;
  break_job_id?: Maybe<Scalars['String']>;
  break_start_at?: Maybe<Scalars['timestamptz']>;
  can_break?: Maybe<Scalars['Boolean']>;
  can_work?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  end_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  last_aasm_state?: Maybe<Scalars['String']>;
  paused_at?: Maybe<Scalars['timestamptz']>;
  pauses_counter: Scalars['Int'];
  start_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  work_end_at?: Maybe<Scalars['timestamptz']>;
  work_end_scheduled_at?: Maybe<Scalars['timestamptz']>;
  work_interval?: Maybe<Scalars['Int']>;
  work_job_id?: Maybe<Scalars['String']>;
  work_start_at?: Maybe<Scalars['timestamptz']>;
};

export type WorkCycleUpdateEventInput = {
  event: Scalars['String'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** ordering argument of a cursor */
export const Cursor_Ordering = {
  /** ascending ordering of the cursor */
  Asc: 'ASC',
  /** descending ordering of the cursor */
  Desc: 'DESC'
} as const;

export type Cursor_Ordering = typeof Cursor_Ordering[keyof typeof Cursor_Ordering];
/** columns and relationships of "devices" */
export type Devices = {
  brand?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamp'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  avg?: Maybe<Devices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
  stddev?: Maybe<Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Devices_Sum_Fields>;
  var_pop?: Maybe<Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Devices_Var_Samp_Fields>;
  variance?: Maybe<Devices_Variance_Fields>;
};


/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Devices_Avg_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>;
  _not?: InputMaybe<Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Devices_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  device_model_id?: InputMaybe<String_Comparison_Exp>;
  device_model_name?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  device_year_class?: InputMaybe<Int_Comparison_Exp>;
  exponent_push_token?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  os_name?: InputMaybe<String_Comparison_Exp>;
  os_version?: InputMaybe<String_Comparison_Exp>;
  platform_api_level?: InputMaybe<Int_Comparison_Exp>;
  total_memory?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export const Devices_Constraint = {
  /** unique or primary key constraint on columns "id" */
  DevicesPkey: 'devices_pkey',
  /** unique or primary key constraint on columns "exponent_push_token", "user_id" */
  DevicesUserExponentPushTokenConstraint: 'devices_user_exponent_push_token_constraint',
  /** unique or primary key constraint on columns "exponent_push_token", "user_id" */
  IndexDevicesOnUserIdAndExponentPushToken: 'index_devices_on_user_id_and_exponent_push_token'
} as const;

export type Devices_Constraint = typeof Devices_Constraint[keyof typeof Devices_Constraint];
/** input type for incrementing numeric columns in table "devices" */
export type Devices_Inc_Input = {
  device_year_class?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  device_model_id?: InputMaybe<Scalars['String']>;
  device_model_name?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  device_year_class?: InputMaybe<Scalars['Int']>;
  exponent_push_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  os_name?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  device_model_id?: Maybe<Scalars['String']>;
  device_model_name?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_year_class?: Maybe<Scalars['Int']>;
  exponent_push_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  manufacturer?: Maybe<Scalars['String']>;
  os_name?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  device_model_id?: InputMaybe<Order_By>;
  device_model_name?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  device_year_class?: InputMaybe<Order_By>;
  exponent_push_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  os_name?: InputMaybe<Order_By>;
  os_version?: InputMaybe<Order_By>;
  platform_api_level?: InputMaybe<Order_By>;
  total_memory?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "devices" */
export const Devices_Select_Column = {
  /** column name */
  Brand: 'brand',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  DeviceModelId: 'device_model_id',
  /** column name */
  DeviceModelName: 'device_model_name',
  /** column name */
  DeviceName: 'device_name',
  /** column name */
  DeviceYearClass: 'device_year_class',
  /** column name */
  ExponentPushToken: 'exponent_push_token',
  /** column name */
  Id: 'id',
  /** column name */
  Manufacturer: 'manufacturer',
  /** column name */
  OsName: 'os_name',
  /** column name */
  OsVersion: 'os_version',
  /** column name */
  PlatformApiLevel: 'platform_api_level',
  /** column name */
  TotalMemory: 'total_memory',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type Devices_Select_Column = typeof Devices_Select_Column[keyof typeof Devices_Select_Column];
/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  device_model_id?: InputMaybe<Scalars['String']>;
  device_model_name?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  device_year_class?: InputMaybe<Scalars['Int']>;
  exponent_push_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  os_name?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Devices_Stddev_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Devices_Stddev_Pop_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Devices_Stddev_Samp_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  device_model_id?: InputMaybe<Scalars['String']>;
  device_model_name?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  device_year_class?: InputMaybe<Scalars['Int']>;
  exponent_push_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  os_name?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform_api_level?: InputMaybe<Scalars['Int']>;
  total_memory?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Devices_Sum_Fields = {
  device_year_class?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  platform_api_level?: Maybe<Scalars['Int']>;
  total_memory?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "devices" */
export const Devices_Update_Column = {
  /** column name */
  Brand: 'brand',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  DeviceModelId: 'device_model_id',
  /** column name */
  DeviceModelName: 'device_model_name',
  /** column name */
  DeviceName: 'device_name',
  /** column name */
  DeviceYearClass: 'device_year_class',
  /** column name */
  ExponentPushToken: 'exponent_push_token',
  /** column name */
  Id: 'id',
  /** column name */
  Manufacturer: 'manufacturer',
  /** column name */
  OsName: 'os_name',
  /** column name */
  OsVersion: 'os_version',
  /** column name */
  PlatformApiLevel: 'platform_api_level',
  /** column name */
  TotalMemory: 'total_memory',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type Devices_Update_Column = typeof Devices_Update_Column[keyof typeof Devices_Update_Column];
export type Devices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Devices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Devices_Var_Pop_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Devices_Var_Samp_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Devices_Variance_Fields = {
  device_year_class?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  platform_api_level?: Maybe<Scalars['Float']>;
  total_memory?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mobility_string_translations" */
export type Mobility_String_Translations = {
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  key: Scalars['String'];
  locale: Scalars['String'];
  translatable_id?: Maybe<Scalars['bigint']>;
  translatable_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "mobility_string_translations" */
export type Mobility_String_Translations_Aggregate = {
  aggregate?: Maybe<Mobility_String_Translations_Aggregate_Fields>;
  nodes: Array<Mobility_String_Translations>;
};

/** aggregate fields of "mobility_string_translations" */
export type Mobility_String_Translations_Aggregate_Fields = {
  avg?: Maybe<Mobility_String_Translations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mobility_String_Translations_Max_Fields>;
  min?: Maybe<Mobility_String_Translations_Min_Fields>;
  stddev?: Maybe<Mobility_String_Translations_Stddev_Fields>;
  stddev_pop?: Maybe<Mobility_String_Translations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobility_String_Translations_Stddev_Samp_Fields>;
  sum?: Maybe<Mobility_String_Translations_Sum_Fields>;
  var_pop?: Maybe<Mobility_String_Translations_Var_Pop_Fields>;
  var_samp?: Maybe<Mobility_String_Translations_Var_Samp_Fields>;
  variance?: Maybe<Mobility_String_Translations_Variance_Fields>;
};


/** aggregate fields of "mobility_string_translations" */
export type Mobility_String_Translations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobility_String_Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mobility_String_Translations_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mobility_string_translations". All fields are combined with a logical 'AND'. */
export type Mobility_String_Translations_Bool_Exp = {
  _and?: InputMaybe<Array<Mobility_String_Translations_Bool_Exp>>;
  _not?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
  _or?: InputMaybe<Array<Mobility_String_Translations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  translatable_id?: InputMaybe<Bigint_Comparison_Exp>;
  translatable_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mobility_string_translations" */
export const Mobility_String_Translations_Constraint = {
  /** unique or primary key constraint on columns "key", "translatable_type", "translatable_id", "locale" */
  IndexMobilityStringTranslationsOnKeys: 'index_mobility_string_translations_on_keys',
  /** unique or primary key constraint on columns "id" */
  MobilityStringTranslationsPkey: 'mobility_string_translations_pkey'
} as const;

export type Mobility_String_Translations_Constraint = typeof Mobility_String_Translations_Constraint[keyof typeof Mobility_String_Translations_Constraint];
/** input type for incrementing numeric columns in table "mobility_string_translations" */
export type Mobility_String_Translations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  translatable_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "mobility_string_translations" */
export type Mobility_String_Translations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  translatable_id?: InputMaybe<Scalars['bigint']>;
  translatable_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mobility_String_Translations_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  translatable_id?: Maybe<Scalars['bigint']>;
  translatable_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mobility_String_Translations_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  translatable_id?: Maybe<Scalars['bigint']>;
  translatable_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mobility_string_translations" */
export type Mobility_String_Translations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobility_String_Translations>;
};

/** on_conflict condition type for table "mobility_string_translations" */
export type Mobility_String_Translations_On_Conflict = {
  constraint: Mobility_String_Translations_Constraint;
  update_columns?: Array<Mobility_String_Translations_Update_Column>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "mobility_string_translations". */
export type Mobility_String_Translations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  translatable_id?: InputMaybe<Order_By>;
  translatable_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mobility_string_translations */
export type Mobility_String_Translations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "mobility_string_translations" */
export const Mobility_String_Translations_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  Key: 'key',
  /** column name */
  Locale: 'locale',
  /** column name */
  TranslatableId: 'translatable_id',
  /** column name */
  TranslatableType: 'translatable_type',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  Value: 'value'
} as const;

export type Mobility_String_Translations_Select_Column = typeof Mobility_String_Translations_Select_Column[keyof typeof Mobility_String_Translations_Select_Column];
/** input type for updating data in table "mobility_string_translations" */
export type Mobility_String_Translations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  translatable_id?: InputMaybe<Scalars['bigint']>;
  translatable_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Mobility_String_Translations_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mobility_String_Translations_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mobility_String_Translations_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mobility_string_translations" */
export type Mobility_String_Translations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobility_String_Translations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobility_String_Translations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  translatable_id?: InputMaybe<Scalars['bigint']>;
  translatable_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Mobility_String_Translations_Sum_Fields = {
  id?: Maybe<Scalars['bigint']>;
  translatable_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "mobility_string_translations" */
export const Mobility_String_Translations_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  Key: 'key',
  /** column name */
  Locale: 'locale',
  /** column name */
  TranslatableId: 'translatable_id',
  /** column name */
  TranslatableType: 'translatable_type',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  Value: 'value'
} as const;

export type Mobility_String_Translations_Update_Column = typeof Mobility_String_Translations_Update_Column[keyof typeof Mobility_String_Translations_Update_Column];
export type Mobility_String_Translations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobility_String_Translations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobility_String_Translations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobility_String_Translations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobility_String_Translations_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mobility_String_Translations_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mobility_String_Translations_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
  translatable_id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  create_attachment?: Maybe<CreateAttachmentOutput>;
  create_attachments?: Maybe<CreateAttachmentsOutput>;
  create_direct_upload?: Maybe<CreateDirectUploadOutput>;
  custom_update_users_by_pk?: Maybe<UpdateUserOutput>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "mobility_string_translations" */
  delete_mobility_string_translations?: Maybe<Mobility_String_Translations_Mutation_Response>;
  /** delete single row from the table: "mobility_string_translations" */
  delete_mobility_string_translations_by_pk?: Maybe<Mobility_String_Translations>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "work_cycles" */
  delete_work_cycles?: Maybe<Work_Cycles_Mutation_Response>;
  /** delete single row from the table: "work_cycles" */
  delete_work_cycles_by_pk?: Maybe<Work_Cycles>;
  destroy_attachment?: Maybe<DestroyAttachmentPayload>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "mobility_string_translations" */
  insert_mobility_string_translations?: Maybe<Mobility_String_Translations_Mutation_Response>;
  /** insert a single row into the table: "mobility_string_translations" */
  insert_mobility_string_translations_one?: Maybe<Mobility_String_Translations>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "work_cycles" */
  insert_work_cycles?: Maybe<Work_Cycles_Mutation_Response>;
  /** insert a single row into the table: "work_cycles" */
  insert_work_cycles_one?: Maybe<Work_Cycles>;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>;
  /** update data of the table: "mobility_string_translations" */
  update_mobility_string_translations?: Maybe<Mobility_String_Translations_Mutation_Response>;
  /** update single row of the table: "mobility_string_translations" */
  update_mobility_string_translations_by_pk?: Maybe<Mobility_String_Translations>;
  /** update multiples rows of table: "mobility_string_translations" */
  update_mobility_string_translations_many?: Maybe<Array<Maybe<Mobility_String_Translations_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "work_cycles" */
  update_work_cycles?: Maybe<Work_Cycles_Mutation_Response>;
  /** update single row of the table: "work_cycles" */
  update_work_cycles_by_pk?: Maybe<Work_Cycles>;
  /** update multiples rows of table: "work_cycles" */
  update_work_cycles_many?: Maybe<Array<Maybe<Work_Cycles_Mutation_Response>>>;
  work_cycle_update_event?: Maybe<WorkCycle>;
};


/** mutation root */
export type Mutation_RootCreate_AttachmentArgs = {
  input: CreateAttachmentInput;
};


/** mutation root */
export type Mutation_RootCreate_AttachmentsArgs = {
  input: CreateAttachmentsInput;
};


/** mutation root */
export type Mutation_RootCreate_Direct_UploadArgs = {
  object: CreateDirectUploadInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_PkArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Mobility_String_TranslationsArgs = {
  where: Mobility_String_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobility_String_Translations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Work_CyclesArgs = {
  where: Work_Cycles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Work_Cycles_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDestroy_AttachmentArgs = {
  input: DestroyAttachmentInput;
};


/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobility_String_TranslationsArgs = {
  objects: Array<Mobility_String_Translations_Insert_Input>;
  on_conflict?: InputMaybe<Mobility_String_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobility_String_Translations_OneArgs = {
  object: Mobility_String_Translations_Insert_Input;
  on_conflict?: InputMaybe<Mobility_String_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Work_CyclesArgs = {
  objects: Array<Work_Cycles_Insert_Input>;
  on_conflict?: InputMaybe<Work_Cycles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Work_Cycles_OneArgs = {
  object: Work_Cycles_Insert_Input;
  on_conflict?: InputMaybe<Work_Cycles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobility_String_TranslationsArgs = {
  _inc?: InputMaybe<Mobility_String_Translations_Inc_Input>;
  _set?: InputMaybe<Mobility_String_Translations_Set_Input>;
  where: Mobility_String_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobility_String_Translations_By_PkArgs = {
  _inc?: InputMaybe<Mobility_String_Translations_Inc_Input>;
  _set?: InputMaybe<Mobility_String_Translations_Set_Input>;
  pk_columns: Mobility_String_Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobility_String_Translations_ManyArgs = {
  updates: Array<Mobility_String_Translations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Work_CyclesArgs = {
  _inc?: InputMaybe<Work_Cycles_Inc_Input>;
  _set?: InputMaybe<Work_Cycles_Set_Input>;
  where: Work_Cycles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Work_Cycles_By_PkArgs = {
  _inc?: InputMaybe<Work_Cycles_Inc_Input>;
  _set?: InputMaybe<Work_Cycles_Set_Input>;
  pk_columns: Work_Cycles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Work_Cycles_ManyArgs = {
  updates: Array<Work_Cycles_Updates>;
};


/** mutation root */
export type Mutation_RootWork_Cycle_Update_EventArgs = {
  work_cycle: WorkCycleUpdateEventInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export const Order_By = {
  /** in ascending order, nulls last */
  Asc: 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst: 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast: 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc: 'desc',
  /** in descending order, nulls first */
  DescNullsFirst: 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast: 'desc_nulls_last'
} as const;

export type Order_By = typeof Order_By[keyof typeof Order_By];
export type Query_Root = {
  /** File relation for active storage models */
  aasm_field?: Maybe<AasmField>;
  /** File relation for active storage models */
  attachment?: Maybe<Attachment>;
  /** Attachments relation for active storage models */
  attachments: Array<Attachment>;
  /** fetch data from the table: "devices" */
  devices: Array<Devices>;
  /** fetch aggregated fields from the table: "devices" */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  me?: Maybe<User>;
  /** fetch data from the table: "mobility_string_translations" */
  mobility_string_translations: Array<Mobility_String_Translations>;
  /** fetch aggregated fields from the table: "mobility_string_translations" */
  mobility_string_translations_aggregate: Mobility_String_Translations_Aggregate;
  /** fetch data from the table: "mobility_string_translations" using primary key columns */
  mobility_string_translations_by_pk?: Maybe<Mobility_String_Translations>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  work_cycle_current?: Maybe<WorkCycle>;
  /** fetch data from the table: "work_cycles" */
  work_cycles: Array<Work_Cycles>;
  /** fetch aggregated fields from the table: "work_cycles" */
  work_cycles_aggregate: Work_Cycles_Aggregate;
  /** fetch data from the table: "work_cycles" using primary key columns */
  work_cycles_by_pk?: Maybe<Work_Cycles>;
};


export type Query_RootAasm_FieldArgs = {
  input: AasmFieldsAttributes;
};


export type Query_RootAttachmentArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootAttachmentsArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootMobility_String_TranslationsArgs = {
  distinct_on?: InputMaybe<Array<Mobility_String_Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobility_String_Translations_Order_By>>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};


export type Query_RootMobility_String_Translations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobility_String_Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobility_String_Translations_Order_By>>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};


export type Query_RootMobility_String_Translations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWork_CyclesArgs = {
  distinct_on?: InputMaybe<Array<Work_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Cycles_Order_By>>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};


export type Query_RootWork_Cycles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Cycles_Order_By>>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};


export type Query_RootWork_Cycles_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_Root = {
  /** fetch data from the table: "devices" */
  devices: Array<Devices>;
  /** fetch aggregated fields from the table: "devices" */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: Array<Devices>;
  /** fetch data from the table: "mobility_string_translations" */
  mobility_string_translations: Array<Mobility_String_Translations>;
  /** fetch aggregated fields from the table: "mobility_string_translations" */
  mobility_string_translations_aggregate: Mobility_String_Translations_Aggregate;
  /** fetch data from the table: "mobility_string_translations" using primary key columns */
  mobility_string_translations_by_pk?: Maybe<Mobility_String_Translations>;
  /** fetch data from the table in a streaming manner: "mobility_string_translations" */
  mobility_string_translations_stream: Array<Mobility_String_Translations>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "work_cycles" */
  work_cycles: Array<Work_Cycles>;
  /** fetch aggregated fields from the table: "work_cycles" */
  work_cycles_aggregate: Work_Cycles_Aggregate;
  /** fetch data from the table: "work_cycles" using primary key columns */
  work_cycles_by_pk?: Maybe<Work_Cycles>;
  /** fetch data from the table in a streaming manner: "work_cycles" */
  work_cycles_stream: Array<Work_Cycles>;
};


export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootMobility_String_TranslationsArgs = {
  distinct_on?: InputMaybe<Array<Mobility_String_Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobility_String_Translations_Order_By>>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};


export type Subscription_RootMobility_String_Translations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobility_String_Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobility_String_Translations_Order_By>>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};


export type Subscription_RootMobility_String_Translations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootMobility_String_Translations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mobility_String_Translations_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobility_String_Translations_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootWork_CyclesArgs = {
  distinct_on?: InputMaybe<Array<Work_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Cycles_Order_By>>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};


export type Subscription_RootWork_Cycles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Cycles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Work_Cycles_Order_By>>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};


export type Subscription_RootWork_Cycles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWork_Cycles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Work_Cycles_Stream_Cursor_Input>>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "users" */
export type Users = {
  allow_password_change?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Attachment>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  /** A computed field, executes function "user_current_password_required" */
  current_password_required?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password: Scalars['String'];
  id: Scalars['bigint'];
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_password_required?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export const Users_Constraint = {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken: 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail: 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken: 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername: 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey: 'users_pkey'
} as const;

export type Users_Constraint = typeof Users_Constraint[keyof typeof Users_Constraint];
/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_password_required?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export const Users_Select_Column = {
  /** column name */
  AllowPasswordChange: 'allow_password_change',
  /** column name */
  ConfirmationSentAt: 'confirmation_sent_at',
  /** column name */
  ConfirmationToken: 'confirmation_token',
  /** column name */
  ConfirmedAt: 'confirmed_at',
  /** column name */
  CountryCode: 'country_code',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Email: 'email',
  /** column name */
  EncryptedPassword: 'encrypted_password',
  /** column name */
  Id: 'id',
  /** column name */
  IsActive: 'is_active',
  /** column name */
  Name: 'name',
  /** column name */
  OtpSecretKey: 'otp_secret_key',
  /** column name */
  PhoneNumber: 'phone_number',
  /** column name */
  RememberCreatedAt: 'remember_created_at',
  /** column name */
  ResetPasswordSentAt: 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken: 'reset_password_token',
  /** column name */
  Role: 'role',
  /** column name */
  UnconfirmedEmail: 'unconfirmed_email',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  Username: 'username'
} as const;

export type Users_Select_Column = typeof Users_Select_Column[keyof typeof Users_Select_Column];
/** input type for updating data in table "users" */
export type Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "users" */
export const Users_Update_Column = {
  /** column name */
  AllowPasswordChange: 'allow_password_change',
  /** column name */
  ConfirmationSentAt: 'confirmation_sent_at',
  /** column name */
  ConfirmationToken: 'confirmation_token',
  /** column name */
  ConfirmedAt: 'confirmed_at',
  /** column name */
  CountryCode: 'country_code',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Email: 'email',
  /** column name */
  EncryptedPassword: 'encrypted_password',
  /** column name */
  Id: 'id',
  /** column name */
  IsActive: 'is_active',
  /** column name */
  Name: 'name',
  /** column name */
  OtpSecretKey: 'otp_secret_key',
  /** column name */
  PhoneNumber: 'phone_number',
  /** column name */
  RememberCreatedAt: 'remember_created_at',
  /** column name */
  ResetPasswordSentAt: 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken: 'reset_password_token',
  /** column name */
  Role: 'role',
  /** column name */
  UnconfirmedEmail: 'unconfirmed_email',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  Username: 'username'
} as const;

export type Users_Update_Column = typeof Users_Update_Column[keyof typeof Users_Update_Column];
export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "work_cycles" */
export type Work_Cycles = {
  aasm_state?: Maybe<Scalars['String']>;
  break_end_at?: Maybe<Scalars['timestamp']>;
  break_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  break_interval?: Maybe<Scalars['Int']>;
  break_job_id?: Maybe<Scalars['String']>;
  break_start_at?: Maybe<Scalars['timestamp']>;
  created_at: Scalars['timestamp'];
  end_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['bigint'];
  last_aasm_state?: Maybe<Scalars['String']>;
  paused_at?: Maybe<Scalars['timestamp']>;
  paused_break?: Maybe<Scalars['Int']>;
  paused_work?: Maybe<Scalars['Int']>;
  pauses_counter?: Maybe<Scalars['Int']>;
  start_at?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_id: Scalars['bigint'];
  work_end_at?: Maybe<Scalars['timestamp']>;
  work_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  work_interval?: Maybe<Scalars['Int']>;
  work_job_id?: Maybe<Scalars['String']>;
  work_start_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "work_cycles" */
export type Work_Cycles_Aggregate = {
  aggregate?: Maybe<Work_Cycles_Aggregate_Fields>;
  nodes: Array<Work_Cycles>;
};

/** aggregate fields of "work_cycles" */
export type Work_Cycles_Aggregate_Fields = {
  avg?: Maybe<Work_Cycles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Work_Cycles_Max_Fields>;
  min?: Maybe<Work_Cycles_Min_Fields>;
  stddev?: Maybe<Work_Cycles_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Cycles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Cycles_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Cycles_Sum_Fields>;
  var_pop?: Maybe<Work_Cycles_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Cycles_Var_Samp_Fields>;
  variance?: Maybe<Work_Cycles_Variance_Fields>;
};


/** aggregate fields of "work_cycles" */
export type Work_Cycles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Work_Cycles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Work_Cycles_Avg_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "work_cycles". All fields are combined with a logical 'AND'. */
export type Work_Cycles_Bool_Exp = {
  _and?: InputMaybe<Array<Work_Cycles_Bool_Exp>>;
  _not?: InputMaybe<Work_Cycles_Bool_Exp>;
  _or?: InputMaybe<Array<Work_Cycles_Bool_Exp>>;
  aasm_state?: InputMaybe<String_Comparison_Exp>;
  break_end_at?: InputMaybe<Timestamp_Comparison_Exp>;
  break_end_scheduled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  break_interval?: InputMaybe<Int_Comparison_Exp>;
  break_job_id?: InputMaybe<String_Comparison_Exp>;
  break_start_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  end_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_aasm_state?: InputMaybe<String_Comparison_Exp>;
  paused_at?: InputMaybe<Timestamp_Comparison_Exp>;
  paused_break?: InputMaybe<Int_Comparison_Exp>;
  paused_work?: InputMaybe<Int_Comparison_Exp>;
  pauses_counter?: InputMaybe<Int_Comparison_Exp>;
  start_at?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  work_end_at?: InputMaybe<Timestamp_Comparison_Exp>;
  work_end_scheduled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  work_interval?: InputMaybe<Int_Comparison_Exp>;
  work_job_id?: InputMaybe<String_Comparison_Exp>;
  work_start_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_cycles" */
export const Work_Cycles_Constraint = {
  /** unique or primary key constraint on columns "id" */
  WorkCyclesPkey: 'work_cycles_pkey'
} as const;

export type Work_Cycles_Constraint = typeof Work_Cycles_Constraint[keyof typeof Work_Cycles_Constraint];
/** input type for incrementing numeric columns in table "work_cycles" */
export type Work_Cycles_Inc_Input = {
  break_interval?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  paused_break?: InputMaybe<Scalars['Int']>;
  paused_work?: InputMaybe<Scalars['Int']>;
  pauses_counter?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  work_interval?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "work_cycles" */
export type Work_Cycles_Insert_Input = {
  aasm_state?: InputMaybe<Scalars['String']>;
  break_end_at?: InputMaybe<Scalars['timestamp']>;
  break_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  break_interval?: InputMaybe<Scalars['Int']>;
  break_job_id?: InputMaybe<Scalars['String']>;
  break_start_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  end_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_aasm_state?: InputMaybe<Scalars['String']>;
  paused_at?: InputMaybe<Scalars['timestamp']>;
  paused_break?: InputMaybe<Scalars['Int']>;
  paused_work?: InputMaybe<Scalars['Int']>;
  pauses_counter?: InputMaybe<Scalars['Int']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  work_end_at?: InputMaybe<Scalars['timestamp']>;
  work_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  work_interval?: InputMaybe<Scalars['Int']>;
  work_job_id?: InputMaybe<Scalars['String']>;
  work_start_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Work_Cycles_Max_Fields = {
  aasm_state?: Maybe<Scalars['String']>;
  break_end_at?: Maybe<Scalars['timestamp']>;
  break_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  break_interval?: Maybe<Scalars['Int']>;
  break_job_id?: Maybe<Scalars['String']>;
  break_start_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  last_aasm_state?: Maybe<Scalars['String']>;
  paused_at?: Maybe<Scalars['timestamp']>;
  paused_break?: Maybe<Scalars['Int']>;
  paused_work?: Maybe<Scalars['Int']>;
  pauses_counter?: Maybe<Scalars['Int']>;
  start_at?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  work_end_at?: Maybe<Scalars['timestamp']>;
  work_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  work_interval?: Maybe<Scalars['Int']>;
  work_job_id?: Maybe<Scalars['String']>;
  work_start_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Work_Cycles_Min_Fields = {
  aasm_state?: Maybe<Scalars['String']>;
  break_end_at?: Maybe<Scalars['timestamp']>;
  break_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  break_interval?: Maybe<Scalars['Int']>;
  break_job_id?: Maybe<Scalars['String']>;
  break_start_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  last_aasm_state?: Maybe<Scalars['String']>;
  paused_at?: Maybe<Scalars['timestamp']>;
  paused_break?: Maybe<Scalars['Int']>;
  paused_work?: Maybe<Scalars['Int']>;
  pauses_counter?: Maybe<Scalars['Int']>;
  start_at?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  work_end_at?: Maybe<Scalars['timestamp']>;
  work_end_scheduled_at?: Maybe<Scalars['timestamp']>;
  work_interval?: Maybe<Scalars['Int']>;
  work_job_id?: Maybe<Scalars['String']>;
  work_start_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "work_cycles" */
export type Work_Cycles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Cycles>;
};

/** on_conflict condition type for table "work_cycles" */
export type Work_Cycles_On_Conflict = {
  constraint: Work_Cycles_Constraint;
  update_columns?: Array<Work_Cycles_Update_Column>;
  where?: InputMaybe<Work_Cycles_Bool_Exp>;
};

/** Ordering options when selecting data from "work_cycles". */
export type Work_Cycles_Order_By = {
  aasm_state?: InputMaybe<Order_By>;
  break_end_at?: InputMaybe<Order_By>;
  break_end_scheduled_at?: InputMaybe<Order_By>;
  break_interval?: InputMaybe<Order_By>;
  break_job_id?: InputMaybe<Order_By>;
  break_start_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_aasm_state?: InputMaybe<Order_By>;
  paused_at?: InputMaybe<Order_By>;
  paused_break?: InputMaybe<Order_By>;
  paused_work?: InputMaybe<Order_By>;
  pauses_counter?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_end_at?: InputMaybe<Order_By>;
  work_end_scheduled_at?: InputMaybe<Order_By>;
  work_interval?: InputMaybe<Order_By>;
  work_job_id?: InputMaybe<Order_By>;
  work_start_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: work_cycles */
export type Work_Cycles_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "work_cycles" */
export const Work_Cycles_Select_Column = {
  /** column name */
  AasmState: 'aasm_state',
  /** column name */
  BreakEndAt: 'break_end_at',
  /** column name */
  BreakEndScheduledAt: 'break_end_scheduled_at',
  /** column name */
  BreakInterval: 'break_interval',
  /** column name */
  BreakJobId: 'break_job_id',
  /** column name */
  BreakStartAt: 'break_start_at',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  EndAt: 'end_at',
  /** column name */
  Id: 'id',
  /** column name */
  LastAasmState: 'last_aasm_state',
  /** column name */
  PausedAt: 'paused_at',
  /** column name */
  PausedBreak: 'paused_break',
  /** column name */
  PausedWork: 'paused_work',
  /** column name */
  PausesCounter: 'pauses_counter',
  /** column name */
  StartAt: 'start_at',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id',
  /** column name */
  WorkEndAt: 'work_end_at',
  /** column name */
  WorkEndScheduledAt: 'work_end_scheduled_at',
  /** column name */
  WorkInterval: 'work_interval',
  /** column name */
  WorkJobId: 'work_job_id',
  /** column name */
  WorkStartAt: 'work_start_at'
} as const;

export type Work_Cycles_Select_Column = typeof Work_Cycles_Select_Column[keyof typeof Work_Cycles_Select_Column];
/** input type for updating data in table "work_cycles" */
export type Work_Cycles_Set_Input = {
  aasm_state?: InputMaybe<Scalars['String']>;
  break_end_at?: InputMaybe<Scalars['timestamp']>;
  break_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  break_interval?: InputMaybe<Scalars['Int']>;
  break_job_id?: InputMaybe<Scalars['String']>;
  break_start_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  end_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_aasm_state?: InputMaybe<Scalars['String']>;
  paused_at?: InputMaybe<Scalars['timestamp']>;
  paused_break?: InputMaybe<Scalars['Int']>;
  paused_work?: InputMaybe<Scalars['Int']>;
  pauses_counter?: InputMaybe<Scalars['Int']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  work_end_at?: InputMaybe<Scalars['timestamp']>;
  work_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  work_interval?: InputMaybe<Scalars['Int']>;
  work_job_id?: InputMaybe<Scalars['String']>;
  work_start_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Work_Cycles_Stddev_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Work_Cycles_Stddev_Pop_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Work_Cycles_Stddev_Samp_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "work_cycles" */
export type Work_Cycles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Cycles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Cycles_Stream_Cursor_Value_Input = {
  aasm_state?: InputMaybe<Scalars['String']>;
  break_end_at?: InputMaybe<Scalars['timestamp']>;
  break_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  break_interval?: InputMaybe<Scalars['Int']>;
  break_job_id?: InputMaybe<Scalars['String']>;
  break_start_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  end_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_aasm_state?: InputMaybe<Scalars['String']>;
  paused_at?: InputMaybe<Scalars['timestamp']>;
  paused_break?: InputMaybe<Scalars['Int']>;
  paused_work?: InputMaybe<Scalars['Int']>;
  pauses_counter?: InputMaybe<Scalars['Int']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
  work_end_at?: InputMaybe<Scalars['timestamp']>;
  work_end_scheduled_at?: InputMaybe<Scalars['timestamp']>;
  work_interval?: InputMaybe<Scalars['Int']>;
  work_job_id?: InputMaybe<Scalars['String']>;
  work_start_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Work_Cycles_Sum_Fields = {
  break_interval?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  paused_break?: Maybe<Scalars['Int']>;
  paused_work?: Maybe<Scalars['Int']>;
  pauses_counter?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
  work_interval?: Maybe<Scalars['Int']>;
};

/** update columns of table "work_cycles" */
export const Work_Cycles_Update_Column = {
  /** column name */
  AasmState: 'aasm_state',
  /** column name */
  BreakEndAt: 'break_end_at',
  /** column name */
  BreakEndScheduledAt: 'break_end_scheduled_at',
  /** column name */
  BreakInterval: 'break_interval',
  /** column name */
  BreakJobId: 'break_job_id',
  /** column name */
  BreakStartAt: 'break_start_at',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  EndAt: 'end_at',
  /** column name */
  Id: 'id',
  /** column name */
  LastAasmState: 'last_aasm_state',
  /** column name */
  PausedAt: 'paused_at',
  /** column name */
  PausedBreak: 'paused_break',
  /** column name */
  PausedWork: 'paused_work',
  /** column name */
  PausesCounter: 'pauses_counter',
  /** column name */
  StartAt: 'start_at',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id',
  /** column name */
  WorkEndAt: 'work_end_at',
  /** column name */
  WorkEndScheduledAt: 'work_end_scheduled_at',
  /** column name */
  WorkInterval: 'work_interval',
  /** column name */
  WorkJobId: 'work_job_id',
  /** column name */
  WorkStartAt: 'work_start_at'
} as const;

export type Work_Cycles_Update_Column = typeof Work_Cycles_Update_Column[keyof typeof Work_Cycles_Update_Column];
export type Work_Cycles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Work_Cycles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Work_Cycles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Cycles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Cycles_Var_Pop_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Work_Cycles_Var_Samp_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Work_Cycles_Variance_Fields = {
  break_interval?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  paused_break?: Maybe<Scalars['Float']>;
  paused_work?: Maybe<Scalars['Float']>;
  pauses_counter?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  work_interval?: Maybe<Scalars['Float']>;
};

export type CreateAttachmentMutationVariables = Exact<{
  signedId: Scalars['String'];
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  attribute: Scalars['String'];
}>;


export type CreateAttachmentMutation = { create_attachment?: { attachment: { id: string, url: string, thumbnail_url?: string | null, created_at: string } } | null };

export type CreateAttachmentsMutationVariables = Exact<{
  signedIds: Array<Scalars['String']> | Scalars['String'];
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  attribute: Scalars['String'];
}>;


export type CreateAttachmentsMutation = { create_attachments?: { attachments: Array<{ id: string, url: string, created_at: string, thumbnail_url?: string | null }> } | null };

export type DestroyAttachmentMutationVariables = Exact<{
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  attribute: Scalars['String'];
}>;


export type DestroyAttachmentMutation = { destroy_attachment?: { __typename: 'DestroyAttachmentPayload' } | null };

export type CreateWorkCycleMutationVariables = Exact<{
  workCycle: Work_Cycles_Insert_Input;
}>;


export type CreateWorkCycleMutation = { insert_work_cycles_one?: { id: number } | null };

export type WorkCycleCurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkCycleCurrentQuery = { work_cycle_current?: { id: number, type?: string | null, workInterval?: number | null, breakInterval?: number | null, aasmState: string, lastAasmState?: string | null, startAt?: string | null, pausedAt?: string | null, endAt?: string | null, canWork?: boolean | null, canBreak?: boolean | null, workStartAt?: string | null, workEndScheduledAt?: string | null, workEndAt?: string | null, workJobId?: string | null, breakStartAt?: string | null, breakEndScheduledAt?: string | null, breakEndAt?: string | null, breakJobId?: string | null, pausesCounter: number, createdAt: string, updatedAt: string, user?: { id: number, name?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null } | null } | null };

export type ActionWorkCycleFragmentFragment = { id: number, type?: string | null, workInterval?: number | null, breakInterval?: number | null, aasmState: string, lastAasmState?: string | null, startAt?: string | null, pausedAt?: string | null, endAt?: string | null, canWork?: boolean | null, canBreak?: boolean | null, workStartAt?: string | null, workEndScheduledAt?: string | null, workEndAt?: string | null, workJobId?: string | null, breakStartAt?: string | null, breakEndScheduledAt?: string | null, breakEndAt?: string | null, breakJobId?: string | null, pausesCounter: number, createdAt: string, updatedAt: string, user?: { id: number, name?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null } | null };

export type WorkCycleUpdateEventMutationVariables = Exact<{
  work_cycle: WorkCycleUpdateEventInput;
}>;


export type WorkCycleUpdateEventMutation = { work_cycle_update_event?: { id: number, type?: string | null, workInterval?: number | null, breakInterval?: number | null, aasmState: string, lastAasmState?: string | null, startAt?: string | null, pausedAt?: string | null, endAt?: string | null, canWork?: boolean | null, canBreak?: boolean | null, workStartAt?: string | null, workEndScheduledAt?: string | null, workEndAt?: string | null, workJobId?: string | null, breakStartAt?: string | null, breakEndScheduledAt?: string | null, breakEndAt?: string | null, breakJobId?: string | null, pausesCounter: number, createdAt: string, updatedAt: string, user?: { id: number, name?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null } | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me?: { id: number, username?: string | null, name?: string | null, email?: string | null, role?: string | null, currentPasswordRequired?: boolean | null, phoneNumber?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  pk_columns: Users_Pk_Columns_Input;
  _set?: InputMaybe<Users_Set_Input>;
}>;


export type UpdateUserMutation = { update_users_by_pk?: { id: number, username?: string | null, name?: string | null, email?: string | null, role?: string | null, phoneNumber?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null } | null };

export type UpsertDeviceMutationVariables = Exact<{
  device: Devices_Insert_Input;
}>;


export type UpsertDeviceMutation = { insert_devices_one?: { id: number } | null };

export type ActionUserFragmentFragment = { id: number, username?: string | null, name?: string | null, email?: string | null, role?: string | null, currentPasswordRequired?: boolean | null, phoneNumber?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null };

export type UserFragmentFragment = { id: number, username?: string | null, name?: string | null, email?: string | null, role?: string | null, phoneNumber?: string | null, avatar?: { url: string, thumbnailUrl?: string | null } | null };

export const ActionWorkCycleFragmentFragmentDoc = gql`
    fragment ActionWorkCycleFragment on WorkCycle {
  id
  type
  workInterval: work_interval
  breakInterval: break_interval
  aasmState: aasm_state
  lastAasmState: last_aasm_state
  startAt: start_at
  pausedAt: paused_at
  endAt: end_at
  canWork: can_work
  canBreak: can_break
  workStartAt: work_start_at
  workEndScheduledAt: work_end_scheduled_at
  workEndAt: work_end_at
  workJobId: work_job_id
  breakStartAt: break_start_at
  breakEndScheduledAt: break_end_scheduled_at
  breakEndAt: break_end_at
  breakJobId: break_job_id
  pausesCounter: pauses_counter
  createdAt: created_at
  updatedAt: updated_at
  user {
    id
    name
    avatar {
      url
      thumbnailUrl: thumbnail_url
    }
  }
}
    `;
export const ActionUserFragmentFragmentDoc = gql`
    fragment ActionUserFragment on User {
  id
  username
  name
  email
  currentPasswordRequired: current_password_required
  phoneNumber: phone_number
  avatar {
    url
    thumbnailUrl: thumbnail_url
  }
  role
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on users {
  id
  username
  name
  email
  phoneNumber: phone_number
  avatar {
    url
    thumbnailUrl: thumbnail_url
  }
  role
}
    `;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($signedId: String!, $relatedId: Int!, $relatedType: String!, $attribute: String!) {
  create_attachment(
    input: {related_id: $relatedId, related_type: $relatedType, attribute: $attribute, signed_id: $signedId}
  ) {
    attachment {
      id
      url
      thumbnail_url
      created_at
    }
  }
}
    `;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      signedId: // value for 'signedId'
 *      relatedId: // value for 'relatedId'
 *      relatedType: // value for 'relatedType'
 *      attribute: // value for 'attribute'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateAttachmentsDocument = gql`
    mutation CreateAttachments($signedIds: [String!]!, $relatedId: Int!, $relatedType: String!, $attribute: String!) {
  create_attachments(
    input: {related_id: $relatedId, related_type: $relatedType, attribute: $attribute, signed_ids: $signedIds}
  ) {
    attachments {
      id
      url
      created_at
      thumbnail_url
    }
  }
}
    `;
export type CreateAttachmentsMutationFn = Apollo.MutationFunction<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>;

/**
 * __useCreateAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentsMutation, { data, loading, error }] = useCreateAttachmentsMutation({
 *   variables: {
 *      signedIds: // value for 'signedIds'
 *      relatedId: // value for 'relatedId'
 *      relatedType: // value for 'relatedType'
 *      attribute: // value for 'attribute'
 *   },
 * });
 */
export function useCreateAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>(CreateAttachmentsDocument, options);
      }
export type CreateAttachmentsMutationHookResult = ReturnType<typeof useCreateAttachmentsMutation>;
export type CreateAttachmentsMutationResult = Apollo.MutationResult<CreateAttachmentsMutation>;
export type CreateAttachmentsMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>;
export const DestroyAttachmentDocument = gql`
    mutation DestroyAttachment($relatedId: Int!, $relatedType: String!, $attribute: String!) {
  destroy_attachment(
    input: {related_id: $relatedId, related_type: $relatedType, attribute: $attribute}
  ) {
    __typename
  }
}
    `;
export type DestroyAttachmentMutationFn = Apollo.MutationFunction<DestroyAttachmentMutation, DestroyAttachmentMutationVariables>;

/**
 * __useDestroyAttachmentMutation__
 *
 * To run a mutation, you first call `useDestroyAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAttachmentMutation, { data, loading, error }] = useDestroyAttachmentMutation({
 *   variables: {
 *      relatedId: // value for 'relatedId'
 *      relatedType: // value for 'relatedType'
 *      attribute: // value for 'attribute'
 *   },
 * });
 */
export function useDestroyAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DestroyAttachmentMutation, DestroyAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyAttachmentMutation, DestroyAttachmentMutationVariables>(DestroyAttachmentDocument, options);
      }
export type DestroyAttachmentMutationHookResult = ReturnType<typeof useDestroyAttachmentMutation>;
export type DestroyAttachmentMutationResult = Apollo.MutationResult<DestroyAttachmentMutation>;
export type DestroyAttachmentMutationOptions = Apollo.BaseMutationOptions<DestroyAttachmentMutation, DestroyAttachmentMutationVariables>;
export const CreateWorkCycleDocument = gql`
    mutation createWorkCycle($workCycle: work_cycles_insert_input!) {
  insert_work_cycles_one(object: $workCycle) {
    id
  }
}
    `;
export type CreateWorkCycleMutationFn = Apollo.MutationFunction<CreateWorkCycleMutation, CreateWorkCycleMutationVariables>;

/**
 * __useCreateWorkCycleMutation__
 *
 * To run a mutation, you first call `useCreateWorkCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkCycleMutation, { data, loading, error }] = useCreateWorkCycleMutation({
 *   variables: {
 *      workCycle: // value for 'workCycle'
 *   },
 * });
 */
export function useCreateWorkCycleMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkCycleMutation, CreateWorkCycleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkCycleMutation, CreateWorkCycleMutationVariables>(CreateWorkCycleDocument, options);
      }
export type CreateWorkCycleMutationHookResult = ReturnType<typeof useCreateWorkCycleMutation>;
export type CreateWorkCycleMutationResult = Apollo.MutationResult<CreateWorkCycleMutation>;
export type CreateWorkCycleMutationOptions = Apollo.BaseMutationOptions<CreateWorkCycleMutation, CreateWorkCycleMutationVariables>;
export const WorkCycleCurrentDocument = gql`
    query WorkCycleCurrent {
  work_cycle_current {
    ...ActionWorkCycleFragment
  }
}
    ${ActionWorkCycleFragmentFragmentDoc}`;

/**
 * __useWorkCycleCurrentQuery__
 *
 * To run a query within a React component, call `useWorkCycleCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkCycleCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkCycleCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkCycleCurrentQuery(baseOptions?: Apollo.QueryHookOptions<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>(WorkCycleCurrentDocument, options);
      }
export function useWorkCycleCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>(WorkCycleCurrentDocument, options);
        }
export type WorkCycleCurrentQueryHookResult = ReturnType<typeof useWorkCycleCurrentQuery>;
export type WorkCycleCurrentLazyQueryHookResult = ReturnType<typeof useWorkCycleCurrentLazyQuery>;
export type WorkCycleCurrentQueryResult = Apollo.QueryResult<WorkCycleCurrentQuery, WorkCycleCurrentQueryVariables>;
export const WorkCycleUpdateEventDocument = gql`
    mutation WorkCycleUpdateEvent($work_cycle: WorkCycleUpdateEventInput!) {
  work_cycle_update_event(work_cycle: $work_cycle) {
    ...ActionWorkCycleFragment
  }
}
    ${ActionWorkCycleFragmentFragmentDoc}`;
export type WorkCycleUpdateEventMutationFn = Apollo.MutationFunction<WorkCycleUpdateEventMutation, WorkCycleUpdateEventMutationVariables>;

/**
 * __useWorkCycleUpdateEventMutation__
 *
 * To run a mutation, you first call `useWorkCycleUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkCycleUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workCycleUpdateEventMutation, { data, loading, error }] = useWorkCycleUpdateEventMutation({
 *   variables: {
 *      work_cycle: // value for 'work_cycle'
 *   },
 * });
 */
export function useWorkCycleUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<WorkCycleUpdateEventMutation, WorkCycleUpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkCycleUpdateEventMutation, WorkCycleUpdateEventMutationVariables>(WorkCycleUpdateEventDocument, options);
      }
export type WorkCycleUpdateEventMutationHookResult = ReturnType<typeof useWorkCycleUpdateEventMutation>;
export type WorkCycleUpdateEventMutationResult = Apollo.MutationResult<WorkCycleUpdateEventMutation>;
export type WorkCycleUpdateEventMutationOptions = Apollo.BaseMutationOptions<WorkCycleUpdateEventMutation, WorkCycleUpdateEventMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...ActionUserFragment
  }
}
    ${ActionUserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($pk_columns: users_pk_columns_input!, $_set: users_set_input) {
  update_users_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpsertDeviceDocument = gql`
    mutation UpsertDevice($device: devices_insert_input!) {
  insert_devices_one(
    object: $device
    on_conflict: {constraint: devices_user_exponent_push_token_constraint, update_columns: [updated_at, deleted_at]}
  ) {
    id
  }
}
    `;
export type UpsertDeviceMutationFn = Apollo.MutationFunction<UpsertDeviceMutation, UpsertDeviceMutationVariables>;

/**
 * __useUpsertDeviceMutation__
 *
 * To run a mutation, you first call `useUpsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDeviceMutation, { data, loading, error }] = useUpsertDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpsertDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDeviceMutation, UpsertDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDeviceMutation, UpsertDeviceMutationVariables>(UpsertDeviceDocument, options);
      }
export type UpsertDeviceMutationHookResult = ReturnType<typeof useUpsertDeviceMutation>;
export type UpsertDeviceMutationResult = Apollo.MutationResult<UpsertDeviceMutation>;
export type UpsertDeviceMutationOptions = Apollo.BaseMutationOptions<UpsertDeviceMutation, UpsertDeviceMutationVariables>;