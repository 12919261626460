export const blue = '#21C5C4';
export const yellow = '#F9E950';
export const pink = '#FF94B2';
export const purple = '#a44cd3';
export const orange = '#DC8665';
export const grey = '#888888';
export const trail = '#444444';
export const trailGray = '#cccccc';

export enum ProgressMode {
  FULL,
  HALF,
  ALMOST_COMPLETE,
  COMPLETE,
  EMPTY,
  BREAK,
}

export enum TimerMode {
  WORK,
  PAUSE,
  STOP,
  BREAK,
  FINISH,
}

export enum PlayingMode {
  BREAK,
  WORK,
  STOP,
}

export enum TimerState {
  STARTED,
  STOPED,
}
