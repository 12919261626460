import {
  DrawerNavigationOptions,
  DrawerScreenProps,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { StackNavigationOptions, StackScreenProps } from '@react-navigation/stack';
import isElectron from 'is-electron';
import { useColorModeValue, useTheme } from 'native-base';
import React, { useContext } from 'react';
import { NotFoundScreen } from '../../../notFound/NotFoundScreen';
import { SettingsScreen } from '../../../settings';
import { TimerScreen } from '../../../timer/TimerScreen';
import { TimerWidget } from '../../../timer/components/TimerWidget/TimerWidget';
import { EditAccountScreen } from '../../../user';
import { WelcomeScreen } from '../../../welcome';
import { HeaderRightMenu } from '../HeaderRightMenu';
import { HomeDrawer } from '../HomeDrawer';
import { WebHeaderTitle } from '../components/WebHeaderTitle';
import { SideMenuContext } from '../context/SidemenuContext';
import { HomeNavigatorStack } from './components/HomeNavigatorStack.web';

export type HomeDrawerParams = {
  Timer: undefined;
  TimerWidget: undefined;
  SetupWizard: undefined;
  Welcome: object;
  Settings: undefined;
  'Edit account': undefined;
  'Not found': undefined;
};

export type HomeScreenNavProps =
  | DrawerScreenProps<HomeDrawerParams>
  | StackScreenProps<HomeDrawerParams>;

export type HomeScreenProps<V extends keyof HomeDrawerParams> =
  | DrawerScreenProps<HomeDrawerParams, V>
  | StackScreenProps<HomeDrawerParams, V>;

const Drawer = createDrawerNavigator<HomeDrawerParams>();

const HomeHeaderRightMenu = () => <HeaderRightMenu hideSearchBar />;

export const HomeNavigator = () => {
  const { colors } = useTheme();
  const stackHeaderBgColor = useColorModeValue(colors.white[900], colors.blueGray[800]);
  const drawerHeaderBgColor = useColorModeValue(colors.primary[900], colors.blueGray[800]);
  const headerTintColor = colors.coolGray[50];
  const { isLargeScreen } = useContext(SideMenuContext);

  const stackScreenOptions: StackNavigationOptions = {
    headerTitle: WebHeaderTitle,
    headerRight: HomeHeaderRightMenu,
    headerStyle: {
      backgroundColor: stackHeaderBgColor,
    },
    headerTintColor,
  };

  const drawerScreenOptions: DrawerNavigationOptions = {
    swipeEnabled: true,
    headerRight: HomeHeaderRightMenu,
    headerStyle: {
      backgroundColor: drawerHeaderBgColor,
    },
    headerTintColor,
  };

  if (isLargeScreen) {
    drawerScreenOptions['drawerType'] = 'permanent';
    drawerScreenOptions['overlayColor'] = 'transparent';
    stackScreenOptions['headerLeft'] = () => null;
  }

  return HomeNavigatorStack ? (
    <HomeNavigatorStack.Navigator screenOptions={stackScreenOptions}>
      {!isElectron() && (
        <HomeNavigatorStack.Screen
          key="Timer"
          name="Timer"
          component={TimerScreen}
          options={{ headerShown: false }}
        />
      )}
      <HomeNavigatorStack.Screen key="Welcome" name="Welcome" component={WelcomeScreen} />
      <HomeNavigatorStack.Screen key="Settings" name="Settings" component={SettingsScreen} />
      <HomeNavigatorStack.Screen
        key="Edit account"
        name="Edit account"
        component={EditAccountScreen}
      />
      {isElectron() && (
        <HomeNavigatorStack.Screen
          key="TimerWidget"
          name="TimerWidget"
          component={TimerWidget}
          options={{ headerShown: false }}
        />
      )}
      <HomeNavigatorStack.Screen key="Not found" name="Not found" component={NotFoundScreen} />
    </HomeNavigatorStack.Navigator>
  ) : (
    <Drawer.Navigator
      useLegacyImplementation={false}
      screenOptions={drawerScreenOptions}
      drawerContent={(props) => <HomeDrawer {...props} />}>
      {!isElectron() && (
        <Drawer.Screen
          key="Timer"
          name="Timer"
          component={TimerScreen}
          options={{ headerShown: false }}
        />
      )}
      <Drawer.Screen key="Welcome" name="Welcome" component={WelcomeScreen} />
      <Drawer.Screen key="Settings" name="Settings" component={SettingsScreen} />
      <Drawer.Screen key="Edit account" name="Edit account" component={EditAccountScreen} />
      {isElectron() && (
        <Drawer.Screen
          key="TimerWidget"
          name="TimerWidget"
          component={TimerWidget}
          options={{ headerShown: false }}
        />
      )}
      <Drawer.Screen key="Not found" name="Not found" component={NotFoundScreen} />
    </Drawer.Navigator>
  );
};
