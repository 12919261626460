import { AntDesign, Entypo, MaterialIcons } from '@expo/vector-icons';
import { Box, IBoxProps, IconButton } from 'native-base';
import React from 'react';
import {
  TimerControlProps as UseTimerControlsProps,
  useTimerControls,
} from '../../hooks/useTimerControls';
import { PlayingMode, TimerMode, trailGray } from '../../types/Timer';

interface TimerControlProps extends IBoxProps {
  timerControls: UseTimerControlsProps;
}

export const TimerControls = (props: TimerControlProps) => {
  const { timerMode, playingMode, playOrPauseTimer, restartTimer, forwardTimer } = useTimerControls(
    props.timerControls
  );
  return (
    <Box flexDirection="row" {...props}>
      <IconButton
        onPress={restartTimer}
        icon={<AntDesign name="reload1" size={36} color={trailGray} />}
      />
      {timerMode === TimerMode.STOP ||
      timerMode === TimerMode.PAUSE ||
      timerMode === TimerMode.FINISH ? (
        <IconButton
          onPress={playOrPauseTimer}
          icon={<Entypo name="controller-play" size={48} color={trailGray} />}
        />
      ) : (
        <IconButton
          onPress={playOrPauseTimer}
          icon={<Entypo name="controller-paus" size={48} color={trailGray} />}
        />
      )}
      {playingMode === PlayingMode.WORK ? (
        <IconButton
          onPress={forwardTimer}
          icon={<MaterialIcons name="free-breakfast" size={48} color={trailGray} />}
        />
      ) : (
        <IconButton
          onPress={forwardTimer}
          icon={<Entypo name="forward" size={48} color={trailGray} />}
        />
      )}
    </Box>
  );
};
