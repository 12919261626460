import { Text, View } from 'native-base';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';
import { useTimerControls } from '../../hooks/useTimerControls';
import {
  PlayingMode,
  ProgressMode,
  TimerMode,
  blue,
  grey,
  pink,
  purple,
  trailGray,
  yellow,
} from '../../types/Timer';
import { WorkCycle } from '../../types/WorkCycle';
import { TimerControls } from '../TimerControls/TimerControls';

export const Timer = () => {
  const currentWorkCycleState = useState<WorkCycle | null>(null);
  const currentSecondsState = useState<number>(0);
  const totalSecondsState = useState<number>(0);
  const progressModeState = useState<ProgressMode>(ProgressMode.FULL);
  const timerModeState = useState<TimerMode>(TimerMode.STOP);
  const lastPlayState = useState<TimerMode>(TimerMode.BREAK);
  const percentageState = useState<number>(0);
  const interval = useRef<NodeJS.Timeout | null>();
  const timeout = useRef<NodeJS.Timeout>();
  const playingModeState = useState(PlayingMode.STOP);
  const { currentSeconds, percentage, progressMode, playingMode, humanTimerMode, startTimer } =
    useTimerControls({
      currentWorkCycleState,
      currentSecondsState,
      totalSecondsState,
      progressModeState,
      timerModeState,
      lastPlayState,
      percentageState,
      playingModeState,
      interval,
      timeout,
    });
  const [label, setLabel] = useState<string>('');

  // Set label and percentage
  useEffect(() => {
    const minutesLeft = Math.floor(currentSeconds / 60);
    const secondsLeft = currentSeconds % 60;
    let minutesLeftLabel = minutesLeft.toFixed(0);
    if (minutesLeft < 10) minutesLeftLabel = `0${minutesLeftLabel}`;
    let secondsLeftLabel = secondsLeft.toFixed(0);
    if (secondsLeft < 10) secondsLeftLabel = `0${secondsLeftLabel}`;
    setLabel(`${minutesLeftLabel}:${secondsLeftLabel}`);
  }, [currentSeconds]);

  // Listen inputs
  // const waitingForInput = () => {};

  // Change progress color
  const progressColor = useMemo(() => {
    if (playingMode === PlayingMode.WORK) {
      switch (progressMode) {
        case ProgressMode.FULL:
          return blue;
        case ProgressMode.HALF:
          return yellow;
        case ProgressMode.ALMOST_COMPLETE:
          return pink;
        case ProgressMode.COMPLETE:
          return pink;
        default:
          return blue;
      }
    }

    if (playingMode === PlayingMode.STOP) return grey;

    if (playingMode === PlayingMode.BREAK) return purple;
  }, [progressMode, playingMode]);

  const progressBarStyles: CircularProgressbarStyles = useMemo(() => {
    const styles = buildStyles({
      pathColor: progressColor,
      textColor: progressColor,
      trailColor: trailGray,
    });
    if (styles['root']) {
      styles['root']['filter'] = 'drop-shadow(3px 3px 30px rgba(51,51,51,0.5))';
    }

    if (styles['text']) {
      styles['text']['fontFamily'] = 'OpenSans_500Medium';
    }

    return styles;
  }, [progressColor]);

  /* eslint-disable-next-line */
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <View
      id="TimerContainer"
      style={{
        aspectRatio: '1/1',
      }}
      justifyContent="center"
      height="50%">
      <CircularProgressbarWithChildren
        strokeWidth={4.5}
        styles={progressBarStyles}
        value={percentage as number}>
        <Text
          color={progressColor}
          fontFamily="OpenSans_500Medium"
          fontSize={96}
          position="relative"
          bottom={-10}>
          {label}
        </Text>
        <Text color={progressColor} fontSize={64} position="relative" top={-10}>
          {humanTimerMode}
        </Text>
      </CircularProgressbarWithChildren>
      <TimerControls
        alignSelf="center"
        mt={30}
        timerControls={{
          currentWorkCycleState,
          currentSecondsState,
          totalSecondsState,
          progressModeState,
          timerModeState,
          lastPlayState,
          playingModeState,
          percentageState,
          interval,
          timeout,
        }}
      />
    </View>
  );
};
