import { differenceInSeconds } from 'date-fns';
import GlobalEvent from 'js-events-listener';
import { MutableRefObject, useCallback, useContext, useEffect, useMemo } from 'react';
import { Sentry } from '../../../../helpers/sentry';
import { AuthContext } from '../../../auth/context';
import { useRealtime } from '../../../realTime/hooks/useRealtime';
import { workCycleCurrentQuery, workCycleUpdateEvent } from '../../timer.api';
import { PlayingMode, ProgressMode, TimerMode } from '../../types/Timer';
import { WorkCycle, WorkCycleState } from '../../types/WorkCycle';
import useTimerConfig from '../useTimerConfig';

export interface OnlineTimerControlProps {
  currentWorkCycleState: [WorkCycle | null, React.Dispatch<React.SetStateAction<WorkCycle | null>>];
  currentSecondsState: [number, React.Dispatch<React.SetStateAction<number>>];
  totalSecondsState: [number, React.Dispatch<React.SetStateAction<number>>];
  progressModeState: [ProgressMode, React.Dispatch<React.SetStateAction<ProgressMode>>];
  timerModeState: [TimerMode, React.Dispatch<React.SetStateAction<TimerMode>>];
  playingModeState: [PlayingMode, React.Dispatch<React.SetStateAction<PlayingMode>>];
  percentageState: [number, React.Dispatch<React.SetStateAction<number>>];
  interval: MutableRefObject<NodeJS.Timeout | null>;
  timeout: MutableRefObject<NodeJS.Timeout | null>;
}

export const useOnlineTimerControls = ({
  currentWorkCycleState,
  currentSecondsState,
  totalSecondsState,
  progressModeState,
  timerModeState,
  percentageState,
  playingModeState,
  interval,
  timeout,
}: OnlineTimerControlProps) => {
  const { currentUser } = useContext(AuthContext);
  const [currentWorkCycle, setCurrentWorkCycle] = currentWorkCycleState;
  const [currentSeconds, setCurrentSeconds] = currentSecondsState;
  const [percentage, setPercentage] = percentageState;
  const [totalSeconds, setTotalSeconds] = totalSecondsState;
  const [progressMode, setProgressMode] = progressModeState;
  const [timerMode, setTimerMode] = timerModeState;
  const [playingMode, setPlayingMode] = playingModeState;
  const { autoPlay } = useTimerConfig();
  const newData = useRealtime<WorkCycle>(
    currentUser?.id ? `${currentUser.id}_work_cycles` : null,
    'update_work_cycle'
  );

  // Fetch current work cycle
  useEffect(() => {
    (async () => {
      const workCycle = await workCycleCurrentQuery();
      if (workCycle) setCurrentWorkCycle(workCycle);
    })();
  }, []);

  useEffect(() => {
    if (newData) {
      setCurrentWorkCycle(newData);
    }
  }, [newData]);

  // Update work cycle events
  const playOrPauseTimer = async () => {
    // // Local state example
    // setCurrentWorkCycle((currentWorkCycle) => {
    //   if (currentWorkCycle?.aasmState === WorkCycleState.WORKING) {
    //     return {
    //       ...currentWorkCycle,
    //       aasmState: WorkCycleState.PAUSED,
    //       pausedAt: new Date().toISOString(),
    //     };
    //   }
    //   if (currentWorkCycle && currentWorkCycle.aasmState === WorkCycleState.PAUSED) {
    //     let workEndScheduledAt;
    //     if (currentWorkCycle.workEndScheduledAt && currentWorkCycle.pausedAt) {
    //       workEndScheduledAt = add(new Date(currentWorkCycle.workEndScheduledAt), {
    //         seconds: differenceInSeconds(new Date(currentWorkCycle.pausedAt), new Date()),
    //       });
    //     }

    //     return {
    //       ...currentWorkCycle,
    //       aasmState: WorkCycleState.WORKING,
    //       workEndScheduledAt: workEndScheduledAt?.toISOString(),
    //     };
    //   }
    //   return currentWorkCycle;
    // });

    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'play_or_pause',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const startTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'start',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const resumeTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'start',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const finishTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'finish',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const pauseTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'pause',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const restartTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'restart',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const forwardTimer = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'forward',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const startBreak = async () => {
    try {
      const workCycle = await workCycleUpdateEvent({
        work_cycle: {
          event: 'break',
        },
      });
      if (workCycle) setCurrentWorkCycle(workCycle);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const reloadStates = useCallback(() => {
    switch (currentWorkCycle?.aasmState) {
      case WorkCycleState.IDLE:
        setTimerMode(TimerMode.STOP);
        setPlayingMode(PlayingMode.STOP);
        setCurrentSeconds(0);
        setTotalSeconds(0);
        setPercentage(0);
        setProgressMode(ProgressMode.EMPTY);
        return;
      case WorkCycleState.WORKING:
        // TODO: work cycle is not returning workEndScheduledAt
        if (currentWorkCycle.workStartAt && currentWorkCycle.workEndScheduledAt) {
          setTimerMode(TimerMode.WORK);
          setPlayingMode(PlayingMode.WORK);
          const currentSeconds = differenceInSeconds(
            new Date(currentWorkCycle.workEndScheduledAt),
            new Date(Date.now())
          );
          const totalSeconds = differenceInSeconds(
            new Date(currentWorkCycle.workEndScheduledAt),
            new Date(currentWorkCycle.workStartAt)
          );

          setCurrentSeconds(currentSeconds > 0 ? currentSeconds : 0);
          setTotalSeconds(totalSeconds);
          const percentage = Math.round(
            (parseFloat((totalSeconds - currentSeconds).toFixed(2)) /
              parseFloat(totalSeconds.toFixed(2))) *
              100
          );
          setPercentage(percentage);
          if (percentage >= 100) {
            setProgressMode(ProgressMode.COMPLETE);
          } else if (percentage >= 75) {
            setProgressMode(ProgressMode.ALMOST_COMPLETE);
          } else if (percentage >= 50) {
            setProgressMode(ProgressMode.HALF);
          } else {
            setProgressMode(ProgressMode.FULL);
          }
        }
        break;
      case WorkCycleState.BREAK:
        if (currentWorkCycle.breakStartAt && currentWorkCycle.breakEndScheduledAt) {
          setTimerMode(TimerMode.BREAK);
          setPlayingMode(PlayingMode.BREAK);
          const currentSeconds = differenceInSeconds(
            new Date(currentWorkCycle.breakEndScheduledAt),
            new Date(Date.now())
          );
          const totalSeconds = differenceInSeconds(
            new Date(currentWorkCycle.breakEndScheduledAt),
            new Date(currentWorkCycle.breakStartAt)
          );
          setCurrentSeconds(currentSeconds > 0 ? currentSeconds : 0);
          setTotalSeconds(totalSeconds);
          const percentage = Math.round(
            (parseFloat((totalSeconds - currentSeconds).toFixed(2)) /
              parseFloat(totalSeconds.toFixed(2))) *
              100
          );
          setPercentage(percentage);
          setProgressMode(ProgressMode.BREAK);
        }
        break;
      case WorkCycleState.PAUSED:
        if (currentWorkCycle.pausedAt) {
          setTimerMode(TimerMode.PAUSE);
          setPlayingMode(PlayingMode.STOP);
          let currentSeconds;
          let totalSeconds;
          if (
            currentWorkCycle.lastAasmState === WorkCycleState.WORKING &&
            currentWorkCycle.workStartAt &&
            currentWorkCycle.workEndScheduledAt
          ) {
            currentSeconds = differenceInSeconds(
              new Date(currentWorkCycle.workEndScheduledAt),
              new Date(currentWorkCycle.pausedAt)
            );
            totalSeconds = differenceInSeconds(
              new Date(currentWorkCycle.workEndScheduledAt),
              new Date(currentWorkCycle.workStartAt)
            );
            if (
              parseFloat(currentSeconds.toFixed(2)) / parseFloat(totalSeconds.toFixed(2)) >=
              1.0
            ) {
              setProgressMode(ProgressMode.COMPLETE);
            } else if (
              parseFloat(currentSeconds.toFixed(2)) / parseFloat(totalSeconds.toFixed(2)) >=
              0.75
            ) {
              setProgressMode(ProgressMode.ALMOST_COMPLETE);
            } else if (
              parseFloat(currentSeconds.toFixed(2)) / parseFloat(totalSeconds.toFixed(2)) >=
              0.5
            ) {
              setProgressMode(ProgressMode.HALF);
            } else {
              setProgressMode(ProgressMode.FULL);
            }
            setTotalSeconds(totalSeconds);
            const percentage = Math.round(
              (parseFloat((totalSeconds - currentSeconds).toFixed(2)) /
                parseFloat(totalSeconds.toFixed(2))) *
                100
            );
            setPercentage(percentage);
            if (percentage >= 100) {
              setProgressMode(ProgressMode.COMPLETE);
            } else if (percentage >= 75) {
              setProgressMode(ProgressMode.ALMOST_COMPLETE);
            } else if (percentage >= 50) {
              setProgressMode(ProgressMode.HALF);
            } else {
              setProgressMode(ProgressMode.FULL);
            }
          } else if (
            currentWorkCycle.lastAasmState === WorkCycleState.BREAK &&
            currentWorkCycle.breakStartAt &&
            currentWorkCycle.breakEndScheduledAt
          ) {
            setProgressMode(ProgressMode.BREAK);
            currentSeconds = differenceInSeconds(
              new Date(currentWorkCycle.breakEndScheduledAt),
              new Date(currentWorkCycle.pausedAt)
            );
            totalSeconds = differenceInSeconds(
              new Date(currentWorkCycle.breakEndScheduledAt),
              new Date(currentWorkCycle.breakStartAt)
            );
            setTotalSeconds(totalSeconds);
            const percentage = Math.round(
              (parseFloat((totalSeconds - currentSeconds).toFixed(2)) /
                parseFloat(totalSeconds.toFixed(2))) *
                100
            );
            setPercentage(percentage);
          }
          setCurrentSeconds(currentSeconds && currentSeconds > 0 ? currentSeconds : 0);
        }
        break;
      case WorkCycleState.FINISHED:
        setTimerMode(TimerMode.FINISH);
        setPlayingMode(PlayingMode.STOP);
        setCurrentSeconds(0);
        setTotalSeconds(0);
        setPercentage(0);
        setProgressMode(ProgressMode.EMPTY);
        return;
      case WorkCycleState.CANCELED:
        setTimerMode(TimerMode.STOP);
        setPlayingMode(PlayingMode.STOP);
        setCurrentSeconds(0);
        setTotalSeconds(0);
        setPercentage(0);
        setProgressMode(ProgressMode.EMPTY);
        return;
      default:
        setTimerMode(TimerMode.STOP);
        setPlayingMode(PlayingMode.STOP);
        setCurrentSeconds(0);
        setTotalSeconds(0);
        setPercentage(0);
        setProgressMode(ProgressMode.EMPTY);
    }
  }, [currentWorkCycle]);

  // Set timer properties by aasm state
  useEffect(() => {
    if (interval.current) clearInterval(interval.current);
    reloadStates();
    interval.current = setInterval(reloadStates, 1000);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [currentWorkCycle]);

  // Human timer mode
  const humanTimerMode: string = useMemo(() => {
    switch (timerMode) {
      case TimerMode.WORK:
        return 'Work';
      case TimerMode.STOP:
        return 'Stop';
      case TimerMode.BREAK:
        return 'Break';
      case TimerMode.PAUSE:
        return currentWorkCycle?.lastAasmState === WorkCycleState.BREAK ? 'Break' : 'Work';
      case TimerMode.FINISH:
        return 'Finish';
    }
  }, [timerMode, currentWorkCycle]);

  // Widget Listeners
  useEffect(() => {
    if (window.api) {
      window.api.on('play', resumeTimer);
      window.api.on('pause', pauseTimer);
      window.api.on('playOrPause', playOrPauseTimer);
      window.api.on('finish', finishTimer);
      window.api.on('restart', restartTimer);
      window.api.on('forward', forwardTimer);

      return () => {
        window.api.removeAllListeners('play');
        window.api.removeAllListeners('pause');
        window.api.removeAllListeners('playOrPause');
        window.api.removeAllListeners('finish');
        window.api.removeAllListeners('restart');
        window.api.removeAllListeners('forward');
      };
    }
  }, [resumeTimer, pauseTimer, finishTimer, restartTimer, playOrPauseTimer, forwardTimer]);

  const startTimerWithDelay = useCallback(
    (delay: number) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(startTimer, delay);
    },
    [startTimer]
  );

  // TODO: Test
  const onFinish = useCallback(() => {
    setTimerMode(TimerMode.FINISH);
    if (currentSeconds === 0 && autoPlay) {
      startTimerWithDelay(5000);
    }
  }, [currentSeconds, autoPlay, startTimerWithDelay, setTimerMode]);

  // Autoplay
  // TODO: Test
  // TODO: Add global event to other events
  useEffect(() => {
    const onFinishEventId = GlobalEvent.on('onfinish', onFinish);
    return () => {
      GlobalEvent.rm(onFinishEventId.toString());
    };
  }, [onFinish]);

  return {
    playOrPauseTimer,
    startTimer,
    resumeTimer,
    pauseTimer,
    finishTimer,
    restartTimer,
    forwardTimer,
    startBreak,
    currentSeconds,
    percentage,
    progressMode,
    humanTimerMode,
    totalSeconds,
    timerMode,
    playingMode,
  };
};
