import { WorkCycleCurrentQuery } from '../../../generated/graphql';

export interface WorkCycle extends NonNullable<WorkCycleCurrentQuery['work_cycle_current']> {
  __typename?: any;
}

export enum WorkCycleState {
  IDLE = 'idle',
  WORKING = 'working',
  PAUSED = 'paused',
  BREAK = 'break',
  FINISHED = 'finished',
  CANCELED = 'canceled',
}
