import { Box, Text } from 'native-base';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';
import { StyleSheet } from 'react-native';
import { useTimerControls } from '../../hooks/useTimerControls';
import {
  PlayingMode,
  ProgressMode,
  TimerMode,
  blue,
  grey,
  pink,
  purple,
  trail,
  yellow,
} from '../../types/Timer';
import { WorkCycle } from '../../types/WorkCycle';
import { onDoubleClick, onMouseDown } from './TimerWidget.events';
import { Countdown, CountdownWrapper } from './TimerWidget.styles';

export const TimerWidget = () => {
  const currentWorkCycleState = useState<WorkCycle | null>(null);
  const currentSecondsState = useState<number>(0);
  const totalSecondsState = useState<number>(ProgressMode.FULL);
  const progressModeState = useState<ProgressMode>(ProgressMode.FULL);
  const playingModeState = useState<PlayingMode>(PlayingMode.STOP);
  const timerModeState = useState<TimerMode>(TimerMode.STOP);
  const lastPlayState = useState<TimerMode>(TimerMode.BREAK);
  const percentageState = useState<number>(0);
  const interval = useRef<any>();
  const timeout = useRef<any>();
  const {
    currentSeconds,
    percentage,
    progressMode,
    timerMode,
    playingMode,
    humanTimerMode,
    startTimer,
  } = useTimerControls({
    currentWorkCycleState,
    currentSecondsState,
    totalSecondsState,
    progressModeState,
    timerModeState,
    playingModeState,
    lastPlayState,
    percentageState,
    interval,
    timeout,
  });

  const [label, setLabel] = useState<string>('');

  // Set label and percentage
  useEffect(() => {
    const minutesLeft = Math.floor(currentSeconds / 60);
    const secondsLeft = currentSeconds % 60;
    let minutesLeftLabel = minutesLeft.toFixed(0);
    if (minutesLeft < 10) minutesLeftLabel = `0${minutesLeftLabel}`;
    let secondsLeftLabel = secondsLeft.toFixed(0);
    if (secondsLeft < 10) secondsLeftLabel = `0${secondsLeftLabel}`;
    setLabel(`${minutesLeftLabel}:${secondsLeftLabel}`);
  }, [currentSeconds]);

  // Listen inputs
  // const waitingForInput = () => {};

  // Change progress color
  const progressColor = useMemo(() => {
    if (playingMode === PlayingMode.WORK) {
      switch (progressMode) {
        case ProgressMode.FULL:
          return blue;
        case ProgressMode.HALF:
          return yellow;
        case ProgressMode.ALMOST_COMPLETE:
          return pink;
        case ProgressMode.COMPLETE:
          return pink;
        default:
          return blue;
      }
    }

    if (playingMode === PlayingMode.STOP) return grey;

    if (playingMode === PlayingMode.BREAK) return purple;
  }, [progressMode, playingMode]);

  const progressBarStyles: CircularProgressbarStyles = useMemo(() => {
    const styles = buildStyles({
      pathColor: progressColor,
      textColor: progressColor,
      trailColor: trail,
    });

    if (styles['text']) {
      styles['text']['fontFamily'] = 'OpenSans_500Medium';
    }

    return styles;
  }, [progressColor]);

  /* eslint-disable-next-line */
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <CountdownWrapper>
      <Countdown
        id="countdown"
        blink={timerMode === TimerMode.FINISH || timerMode === TimerMode.PAUSE ? 'true' : undefined}
        onMouseDown={onMouseDown}
        onDoubleClick={onDoubleClick}>
        <Box style={styles.label}>
          <Text style={[styles.font, { color: progressColor }]}>{humanTimerMode}</Text>
        </Box>
        <CircularProgressbar styles={progressBarStyles} value={percentage as number} text={label} />
      </Countdown>
    </CountdownWrapper>
  );
};

const styles = StyleSheet.create({
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    top: 15,
    right: 0,
    userSelect: 'none',
  },
  font: {
    fontSize: 10,
    letterSpacing: 1.5,
  },
});
