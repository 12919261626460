import GlobalEvent from 'js-events-listener';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { PlayingMode, ProgressMode, TimerMode } from '../../types/Timer';
import { useTimerAudio } from '../useTimerAudio/useTimerAudio';
import useTimerConfig from '../useTimerConfig';

interface StartTimerProps {
  delay?: number;
}
type StartTimer = (props?: StartTimerProps) => void;

export interface TimerControlsResponse {
  playOrPauseTimer: () => void;
  startTimer: () => void;
  pauseTimer: () => void;
  finishTimer: () => void;
  restartTimer: () => void;
  startBreak: () => void;
  forwardTimer: () => void;
  currentSeconds: number;
  percentage: number;
  progressMode: ProgressMode;
  timerMode: TimerMode;
  playingMode: PlayingMode;
  humanTimerMode: string;
}

export interface OfflineTimerControlProps {
  currentSecondsState: [number, React.Dispatch<React.SetStateAction<number>>];
  progressModeState: [ProgressMode, React.Dispatch<React.SetStateAction<ProgressMode>>];
  timerModeState: [TimerMode, React.Dispatch<React.SetStateAction<TimerMode>>];
  lastPlayState: [TimerMode, React.Dispatch<React.SetStateAction<TimerMode>>];
  percentageState: [number, React.Dispatch<React.SetStateAction<number>>];
  interval: MutableRefObject<any>;
  timeout: MutableRefObject<any>;
}

type UseOfflineTimerControls = (props: OfflineTimerControlProps) => TimerControlsResponse;

export const useOfflineTimerControls: UseOfflineTimerControls = ({
  currentSecondsState,
  progressModeState,
  timerModeState,
  lastPlayState,
  percentageState,
  interval,
  timeout,
}) => {
  const [progressMode, setProgressMode] = progressModeState;
  const [currentSeconds, setCurrentSeconds] = currentSecondsState;
  const [timerMode, setTimerMode] = timerModeState;
  const [percentage, setPercentage] = percentageState;
  const [lastPlayType, setLastPlayType] = lastPlayState;
  const { pomodoroSeconds, breakSeconds, muted, autoPlay } = useTimerConfig();
  const {
    playTick,
    pauseTick,
    isPlayingTick,
    resetTick,
    playBell,
    pauseBell,
    isPlayingBell,
    stopSounds,
  } = useTimerAudio();

  // Calculate totalSeconds
  const totalSeconds = useMemo(() => {
    if (timerMode === TimerMode.PAUSE) {
      return lastPlayType === TimerMode.BREAK ? breakSeconds : pomodoroSeconds;
    } else if (timerMode === TimerMode.BREAK) {
      return breakSeconds;
    } else if (timerMode === TimerMode.WORK) {
      return pomodoroSeconds;
    } else {
      return 0;
    }
  }, [timerMode, pomodoroSeconds, breakSeconds]);

  // Set percentage
  useEffect(() => {
    const percentage = 100 - (currentSeconds / totalSeconds) * 100;
    setPercentage(percentage);
  }, [currentSeconds, timerMode, totalSeconds]);

  // Start interval
  const startTimerInterval = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    const intervalId = setInterval(() => {
      setCurrentSeconds((currentSeconds) => {
        if (currentSeconds === 1) {
          pauseTick();
          if (!muted) playBell();
        } else {
          if (currentSeconds === 6) {
            resetTick();
          }
          if (currentSeconds > 1 && currentSeconds < 6) {
            if (!muted) {
              playTick();
            }
          }
        }

        if (currentSeconds > 0) {
          return --currentSeconds;
        } else {
          if (interval.current) clearInterval(interval.current);
          GlobalEvent.emit('onfinish', null);
          return currentSeconds;
        }
      });
    }, 1000);
    interval.current = intervalId;
  }, [muted, setCurrentSeconds, pauseTick, playBell, resetTick, playTick]);

  const pauseSounds = () => {
    pauseTick();
    pauseBell();
  };

  const resumeSounds = useCallback(() => {
    if (currentSeconds === 1 && isPlayingBell()) playBell();
    if (currentSeconds > 1 && currentSeconds <= 6 && isPlayingTick()) playTick();
  }, [currentSeconds, playBell, playTick, isPlayingBell, isPlayingTick]);

  // Start timer (manage interval)
  const startTimer = useCallback<StartTimer>(() => {
    setTimerMode((timerMode) => {
      let _timerMode = timerMode;
      if (
        timerMode === TimerMode.STOP ||
        timerMode === TimerMode.PAUSE ||
        timerMode === TimerMode.FINISH
      ) {
        if (lastPlayType === TimerMode.WORK) {
          _timerMode = TimerMode.BREAK;
          setLastPlayType(TimerMode.BREAK);
          setCurrentSeconds(breakSeconds);
        } else {
          _timerMode = TimerMode.WORK;
          setLastPlayType(TimerMode.WORK);
          setCurrentSeconds(pomodoroSeconds);
        }

        startTimerInterval();
        GlobalEvent.emit('onstart', null);
      }

      return _timerMode;
    });
  }, [startTimerInterval, pomodoroSeconds, breakSeconds, lastPlayType]);

  const startTimerWithDelay = useCallback(
    (delay: number) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(startTimer, delay);
    },
    [startTimer]
  );

  const onFinish = useCallback(() => {
    setTimerMode(TimerMode.FINISH);
    if (currentSeconds === 0 && autoPlay) {
      startTimerWithDelay(5000);
    }
  }, [currentSeconds, autoPlay, startTimerWithDelay, setTimerMode]);

  // Autoplay
  useEffect(() => {
    const onFinishEventId = GlobalEvent.on('onfinish', onFinish);
    return () => {
      GlobalEvent.rm(onFinishEventId.toString());
    };
  }, [onFinish]);

  const clearTimerInterval = () => {
    if (interval.current) clearInterval(interval.current);
    interval.current = undefined;
  };

  const resumeTimer = useCallback(() => {
    if (currentSeconds <= 0) {
      startTimer();
    } else {
      startTimerInterval();
      setTimerMode(lastPlayType);
      resumeSounds();
    }
  }, [currentSeconds, lastPlayType, startTimer, startTimerInterval]);

  // Pause timer
  const pauseTimer = useCallback(() => {
    clearTimerInterval();
    setTimerMode(TimerMode.PAUSE);
    pauseSounds();
    GlobalEvent.emit('onpause', null);
  }, [pauseSounds, clearTimerInterval, setTimerMode]);

  // Stop timer
  const finishTimer = useCallback(() => {
    clearTimerInterval();
    setCurrentSeconds(0);
    setLastPlayType((lastPlayType) =>
      lastPlayType === TimerMode.BREAK ? TimerMode.WORK : TimerMode.BREAK
    );
    setTimerMode(TimerMode.STOP);
    stopSounds();
    GlobalEvent.emit('onstop', null);
  }, []);

  // Restart timer
  const restartTimer = useCallback(() => {
    setCurrentSeconds(0);
    stopSounds();
    setLastPlayType((lastPlayType) =>
      lastPlayType === TimerMode.BREAK ? TimerMode.WORK : TimerMode.BREAK
    );
    GlobalEvent.emit('onrestart', null);
  }, []);

  // Restart timer
  const forwardTimer = useCallback(() => {
    setCurrentSeconds(0);
    stopSounds();
    GlobalEvent.emit('onrestart', null);
  }, []);

  const startBreak = () => {};

  const playOrPauseTimer = useCallback(() => {
    if (timerMode === TimerMode.PAUSE) {
      resumeTimer();
    } else if (timerMode === TimerMode.WORK || timerMode === TimerMode.BREAK) {
      pauseTimer();
    } else if (timerMode === TimerMode.STOP || timerMode === TimerMode.FINISH) {
      startTimer();
    } else {
      startTimer();
    }
  }, [startTimer, pauseTimer, resumeTimer, timerMode]);

  // Set progress mode
  useEffect(() => {
    let progressMode = ProgressMode.FULL;
    if (
      timerMode === TimerMode.WORK ||
      (timerMode === TimerMode.PAUSE && lastPlayType === TimerMode.WORK)
    ) {
      if (percentage >= 0 && percentage < 50) {
        progressMode = ProgressMode.FULL;
      } else if (percentage >= 50 && percentage < 75) {
        progressMode = ProgressMode.HALF;
      } else if (percentage >= 75 && percentage < 100) {
        progressMode = ProgressMode.ALMOST_COMPLETE;
      }
    }

    if (timerMode === TimerMode.BREAK && percentage > 0) {
      progressMode = ProgressMode.BREAK;
    }

    if (percentage === 0) {
      progressMode = ProgressMode.EMPTY;
    }

    if (percentage === 100) {
      progressMode = ProgressMode.COMPLETE;
    }
    setProgressMode(progressMode);
  }, [percentage, progressMode, timerMode]);

  // Widget Listeners
  useEffect(() => {
    if (window.api) {
      window.api.on('play', resumeTimer);
      window.api.on('pause', pauseTimer);
      window.api.on('playOrPause', playOrPauseTimer);
      window.api.on('stop', finishTimer);
      window.api.on('restart', restartTimer);
      window.api.on('next', forwardTimer);

      return () => {
        window.api.removeAllListeners('play');
        window.api.removeAllListeners('pause');
        window.api.removeAllListeners('playOrPause');
        window.api.removeAllListeners('stop');
        window.api.removeAllListeners('next');
        window.api.removeAllListeners('restart');
      };
    }
  }, [resumeTimer, pauseTimer, finishTimer, restartTimer, playOrPauseTimer, forwardTimer]);

  const humanTimerMode: string = useMemo(() => {
    switch (timerMode) {
      case TimerMode.WORK:
        return 'Work';
      case TimerMode.STOP:
        return 'Stop';
      case TimerMode.BREAK:
        return 'Break';
      case TimerMode.PAUSE:
        return lastPlayType === TimerMode.BREAK ? 'Break' : 'Work';
      case TimerMode.FINISH:
        return 'Finish';
    }
  }, [timerMode, lastPlayType]);

  const playingMode = useMemo(() => {
    if (timerMode === TimerMode.PAUSE) {
      if (lastPlayType === TimerMode.BREAK) return PlayingMode.BREAK;
      return PlayingMode.WORK;
    } else {
      if (timerMode === TimerMode.WORK) return PlayingMode.WORK;
      if (timerMode === TimerMode.BREAK) return PlayingMode.BREAK;
      return PlayingMode.STOP;
    }
  }, [timerMode, lastPlayType]);

  return {
    playOrPauseTimer,
    startTimer,
    pauseTimer,
    finishTimer,
    restartTimer,
    forwardTimer,
    startBreak,
    currentSeconds,
    percentage,
    progressMode,
    humanTimerMode,
    totalSeconds,
    timerMode,
    playingMode,
  };
};
