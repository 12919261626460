import { DefaultTheme, NavigationContainer, getStateFromPath } from '@react-navigation/native';
import isElectron from 'is-electron';
import React, { useContext, useEffect } from 'react';
import { AppContent } from '..';
import { AuthEmailNavigator } from '../../auth';
import { AuthContext } from '../../auth/context';
import { SideMenuProvider } from '../../home/navigation/context/SidemenuContext';
import { Loading } from '../../loading';

const getStateFromUrl = (_url: string, options: any) => {
  const url = new URL(_url);
  const urlSearchParams = new URLSearchParams(url.search);
  const routeName = Object.keys(options.screens).filter((key) => {
    if (urlSearchParams.get('location') === options.screens[key]) return key;
  });

  if (routeName[0]) {
    return {
      routes: [
        {
          name: routeName[0],
        },
      ],
    };
  }
};

const _getStateFromPath = (_url: string, options: any) => {
  if (isElectron()) {
    const url = _url[0] === '/' ? `file://${_url}` : _url;

    const state = getStateFromUrl(url, options);
    if (state) return state;
  }
  return getStateFromPath(_url, options);
};

export const AppNavigator: React.FC = () => {
  const { isSignedIn, userLoading } = useContext(AuthContext);

  const linking = {
    prefixes: ['https://my.focusfocus.app', 'focusfocus://'],
  };

  const linkingAuth = {
    ...linking,
    config: {
      screens: Object.assign(
        {
          SignUp: 'sign_up',
          SignIn: 'sign_in',
          ForgotPassword: 'forgot_password',
          // 'Not found': '*',
        },
        isElectron()
          ? {
              TimerWidget: 'timerwidget',
            }
          : {
              Timer: '/',
            }
      ),
    },
    getStateFromPath: _getStateFromPath,
  };

  const linkingMain = {
    ...linking,
    config: {
      screens: Object.assign(
        {
          SetupWizard: 'setup',
          Welcome: 'welcome',
          Settings: 'settings',
          'Edit account': 'edit_account',
          // 'Not found': '*',
        },
        isElectron()
          ? {
              TimerWidget: 'timerwidget',
            }
          : {
              Timer: '/',
            }
      ),
    },
    getStateFromPath: _getStateFromPath,
  };

  useEffect(() => {}, []);

  const ElectronTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'rgba(255,255,255,0)',
    },
  };

  return userLoading ? (
    <Loading />
  ) : isSignedIn ? (
    <SideMenuProvider>
      <NavigationContainer
        theme={isElectron() ? ElectronTheme : DefaultTheme}
        linking={linkingMain}
        fallback={<Loading />}>
        <AppContent />
      </NavigationContainer>
    </SideMenuProvider>
  ) : (
    <NavigationContainer theme={isElectron() ? ElectronTheme : DefaultTheme} linking={linkingAuth}>
      <AuthEmailNavigator />
    </NavigationContainer>
  );
};
